export const routes = {
  contactUs: "/contact-us",
  register: "/register",
  login: "/login",
  myOrders: "/my-orders",
  activateOTP: "/activate-account",
  forgotPassword: "/forgot-password",
  singleProduct: "/product-description",
  productCategory: "/Product-category",
  cartPage: "/cart",
  resetpassword: "/reset-password",
  guestsignin: "/guest-login",
  registeruseraddress: "/registered-address",
  guestuseraddress: "/guest-address",
  errorPage: "/page-not-found",
  profile: "/my-profile",
  wishlist: "/my-wishlist",
  privacyPolicy:"/privacy-policy",
  termsofuse:"/terms-conditions"
};
