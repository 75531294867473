import { routes } from "routes/navigation";

export const socialMediaLInks = [
  { icon: "facebook", link: "/" },
  {
    icon: "instagram",
    link: "/",
  },
  {
    icon: "twitter",
    link: "/",
  },
];

export const companyInfo = [
  { item: "Contact us", link: routes.contactUs },
  { item: "Terms Of Use", link: routes.termsofuse },
  { item: "Privacy Policy", link: routes.privacyPolicy },
  // { item: "Shipping & Delivery", link: "/" },
];

export const dropDownIcons = [
  {
    icon: "Profile",
    link: "/my-profile",
  },
  {
    icon: "My Orders",
    link: "/my-orders",
  },
  {
    icon: "Support",
    link: "/contact-us",
  },
  {
    icon: "Logout",
    link: "/",
  },
];

export const headerIcons = [
  {
    icon: "wishlist",
    link: "/my-wishlist",
  },
  {
    icon: "Cart",
    link: "/cart",
  },
];

export const privacyPolicy = [
  {
    title: "Information We Collect",
    description:
      "When you use our online store, we may collect the following types of personal information:",
    subpolicies: [
      {
        subpolicyHeading: "Contact Information",
        subDesc:
          "This may include your name, email address, phone number, and shipping address.",
      },
      {
        subpolicyHeading: "Payment Information",
        subDesc:
          "When you make a purchase, we collect payment information, including credit card details and billing address.",
      },
      {
        subpolicyHeading: "Device and Usage Information",
        subDesc:
          "We may collect information about the device you use to access our website, such as IP address, browser type, and operating system. We also collect data about your interactions with our website, such as pages visited and actions taken.",
      },
      {
        subpolicyHeading: "Communication",
        subDesc:
          "If you contact us, we may keep a record of that communication.",
      },
    ],
  },
  {
    title: "How We Use Your Information",
    description: "We use your personal information for the following purposes:",
    subpolicies: [
      {
        subpolicyHeading: "Provide Products and Services",
        subDesc:
          "We use your information to process orders, deliver products, and provide customer support.",
      },
      {
        subpolicyHeading: "Payment Processing",
        subDesc:
          "To process your payments and prevent fraudulent transactions.",
      },
      {
        subpolicyHeading: "Improve Our Services",
        subDesc:
          "We use data to understand how our website is used, identify areas for improvement, and enhance your user experience.",
      },
      {
        subpolicyHeading: "Communication",
        subDesc:
          "We may send you emails regarding your orders, updates, promotional offers, and newsletters. You can opt-out of marketing communications at any time.",
      },
      {
        subpolicyHeading: "Legal Compliance",
        subDesc: "To comply with applicable laws and regulations.",
      },
    ],
  },
  {
    title: "Sharing Your Information",
    description:
      "We do not sell, trade, or rent your personal information to third parties for their marketing purposes. However, we may share your information with:",
    subpolicies: [
      {
        subpolicyHeading: "Service Providers",
        subDesc:
          "We may share your information with third-party service providers who help us with payment processing, order fulfillment, shipping, and other operational tasks.",
      },
      {
        subpolicyHeading: "Legal Obligations",
        subDesc:
          "We may disclose your information to comply with legal obligations, such as responding to court orders or law enforcement requests.",
      },
      {
        subpolicyHeading: "Business Transfers",
        subDesc:
          "In the event of a merger, acquisition, or sale of all or part of our business, your information may be transferred to the acquiring entity.",
      },
    ],
  },
  {
    title: "Your Privacy Choices",
    subpolicies: [
      {
        subpolicyHeading: "Access and Update",
        subDesc:
          "You can access and update your personal information by logging into your account on our website.",
      },
      {
        subpolicyHeading: "Marketing Communications",
        subDesc:
          "You can opt-out of marketing communications by following the instructions in our emails or contacting us.",
      },
      {
        subpolicyHeading: "Cookies",
        subDesc:
          "You can manage your cookie preferences through your browser settings.",
      },
    ],
  },
  {
    title: "Storage and Security",
    description:
      "We will take all reasonable steps to protect the personal information that we hold from misuse, loss, or unauthorized access or modification. If you suspect any misuse or loss of, or unauthorized access to your personal information, please let us know immediately. When using our website, your information will pass through a secure server using SSL (secure sockets layer) encryption technology. The encryption process ensures that your information cannot be read by or altered by outside influences. Please note we do not store any credit card information.",
  },
  {
    title: "Third-Party Websites",
    description:
      "This website may contain links to third-party websites. We are not responsible for the content of third-party websites or the manner in which those websites collect, store, use, and distribute any personal information you provide.",
  },
  {
    title: "Children's Privacy",
    description:
      "Our website is not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children.",
  },
  {
    title: "Unsubscribing to Electronic Messages",
    description:
      "If you no longer wish to receive marketing emails from us, you may unsubscribe by using the link found in any promotional email, logging into your account and changing your preferences, or contacting us at sales@bytebazaar.co.nz.",
  },
  {
    title: "Changes to this Privacy Policy",
    description:
      "We reserve the right to change any of our Privacy Policy displayed on this website at any time. By continuing to use this website, it shall be deemed that you agree to be bound by the amended Privacy Policy as posted on this website.",
  },
  {
    title: "Contact Us",
    description:
      "If you have questions or concerns about this Privacy Policy or your personal information, please contact us at Sale@bytebazaar.co.nz. By using our online store, you consent to the collection and use of your personal information as described in this Privacy Policy.",
  },
];

export const Termsandconditions = [
  {
    title: "Terms and Conditions",
    description:
      "By making a purchase, you agree to adhere to the terms and conditions outlined in this sales agreement, unless you've entered into a separate formal purchase agreement with Byte Bazaar Ltd, in which case the separate agreement will take precedence.",
  },
  {
    title: "Contract Formation",
    description:
      "When you place an order through our online store or any other means, you are essentially offering to purchase our products or services under our terms and conditions. It's important to note that payment processing or order confirmation doesn't constitute acceptance by us. The contract becomes official and binding only when we dispatch the goods.",
  },
  {
    title: "Consumer Guarantees Act 1993",
    description:
      "If you're acquiring our products or services for business purposes, the consumer guarantees stipulated in the Consumer Guarantees Act (CGA) won't apply. However, if your acquisition is for non-business purposes, these terms will be interpreted in accordance with the CGA, and nothing in these terms will limit your rights under the Act. We do not provide any Express Guarantees as defined in the Act.",
  },
  {
    title: "Errors or Omissions",
    description:
      "We reserve the right to correct any errors or omissions, whether they're related to advertising, quotations, pricing, invoices, or acknowledgments. While we strive to maintain accurate stock levels and pricing, we cannot be held responsible for inaccuracies. If we discover an error or omission, we'll promptly inform you and provide a full refund.",
  },
  {
    title: "Description of Goods",
    description:
      "Although we make every effort to provide precise product descriptions on our website, we rely on information from our suppliers and manufacturers. We cannot be held accountable for errors in product information, images, or video content. If the goods you receive don't match our website's description, please notify us immediately, and we'll take appropriate action. We are not obligated to provide a higher-value item if we cannot source the correct item at the same or lower price as the original. A full refund for the original item will suffice.",
  },
  {
    title: "Delivery of Goods",
    description:
      "Any delivery timeframes mentioned by us are provided in good faith but are only estimates. We won't be responsible for any losses or damages incurred due to delivery delays. We reserve the right to dispatch goods in one shipment or in instalments. All goods are shipped using a signature courier delivery service. If your delivery address has an agreement with our courier providers or you request a no-signature delivery, we accept no responsibility for lost goods. In the case of delivery to an apartment, shared living or working space with a designated courier drop-off area or mailroom, delivery to this area is considered final, and we are not responsible for any misplaced or lost goods.",
  },
  {
    title: "Out of Stock Products & Backorders",
    description:
      "If a product you ordered is later found to be out of stock, we may place your order on backorder and provide an estimated time of arrival. In such cases, you may request an alternative (similar) product if available. Price differences, whether higher or lower, will be adjusted accordingly, or we can offer a full refund if the ETA is too far out.",
  },
  {
    title: "Return of Goods for Credit or Refund",
    description:
      "Returns for credit/refund are accepted based on the reason for return, subject to our discretion. Goods that have not been opened and have undamaged packaging can be fully credited within 7 days of delivery. Items returned outside of the 7-day period may be accepted with a restocking fee based on special circumstances. Software cannot be returned for credit due to copyright regulations.",
  },
  {
    title: "Faulty Returns",
    description:
      "Returns for faulty items are covered by the warranty associated with the product, which is typically one year or more for hardware. Software, toners, cartridges, and paper do not have warranties. Compatibility issues are the responsibility of the customer. We charge a restocking fee for returns outside of the return period or for damaged or used items.",
  },
  {
    title: "Shipping of Faulty Products or Credits",
    description:
      "Customers are responsible for shipping or delivering items for return, and we will organize return freight for replacement products. This procedure follows the 'return to base' process. If we send the wrong product due to our error, we will cover the return freight.",
  },
  {
    title: "How to Process a Return or Credit?",
    description:
      "To initiate a return or credit, visit our returns and repairs page, fill in the required details, and submit the form. Our returns team will provide a return number and instructions for returning the product for repair, replacement, or credit. You will receive a response within 24 hours on weekdays.",
  },
  {
    title: "N.Z. Warranties",
    description:
      "All our products come from New Zealand distributors, so all warranties are supported within New Zealand. In rare cases when a product is out of stock or obsolete, we may experience delays in providing replacements.",
  },
  {
    title: "Seller's Liability and Maintenance Guarantee",
    description:
      "The buyer is responsible for ensuring that the ordered goods are suitable for their intended purpose before purchasing. The seller's liability is limited to the guarantees provided by the manufacturer. Our warranty doesn't cover damage resulting from misuse, accidents, negligence, improper operation, maintenance, installation, modification, adjustment, or overclocking.",
  },
  {
    title: "Force Majeure",
    description:
      "We are not liable for any loss or damage arising from delays or failures to perform any term of the contract due to circumstances beyond our control, such as acts of God, fires, conflicts, labour disputes, civil commotion, government intervention, inability to obtain labour or materials, accidents, transportation interruptions, or other causes.",
  },
  {
    title: "Disputes and Proper Law",
    description:
      "Any disputes arising from this contract will be subject to arbitration in accordance with the Arbitration Act 1996. This contract and its terms and conditions are governed by New Zealand law.",
  },
  {
    title: "Changes to Terms and Conditions",
    description:
      "We reserve the right to amend any displayed terms and conditions (including our Privacy Policy) on this website at any time. Your continued use of this website will signify your agreement to be bound by the revised terms and conditions posted on the site.",
  },
];



