import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OrderConfirmationImage from "assets/images/booking.png";
import OrderCanceledImage from "assets/images/Ordercancel.png";
import { useParams, Outlet } from "react-router-dom";
import axios from "axios";
import { baseURL } from "api/apiUrls";
import { CheckOutSteps, Seo } from "components";

function OrderConfirmationStatus() {
  const params = useParams();
  const { orderStatus } = params;
  const url = new URL(window.location.href);
  const sessionId = url.searchParams.get("sessionId");
  const token = sessionStorage.getItem("accessToken");
  const [allDetails, setAllDetails] = useState({});

  useEffect(() => {
    const getAllDetails = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/Orders/verifypayment/?wind_session_id=${sessionId}`,
        );
        setAllDetails(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllDetails();
  }, []);

  return (
    <>
      <Seo
        metaTitle={`Order ${orderStatus}`}
        metaDescription='order'
      />
      <div className='Order-Status-Success container m-auto'>
        <div className='guest-registered-adress'>
          <CheckOutSteps />
        </div>
        <div className='m-auto'>
          <div className='Order-Status-Success-image'>
            <img
              className='d-block m-auto'
              src={
                orderStatus === "success"
                  ? OrderConfirmationImage
                  : OrderCanceledImage
              }
              alt={orderStatus}
            />
          </div>
          <h4 className='text-center text-semibold'>
            {" "}
            {orderStatus === "success" ? (
              <span>Your Order has been Confirmed</span>
            ) : (
              <span>Something went Wrong</span>
            )}{" "}
          </h4>
          <p className='text-center mb-2'>
            Payment status:{" "}
            <strong style={{ textTransform: "capitalize" }}>
              {orderStatus}
            </strong>{" "}
          </p>
          <p className='text-center'>Check Your Email for Updates</p>
          {token && (
            <p className='text-center mb-md-5 mb-2'>
              <Link
                to={"/my-orders"}
                className='text-center'
              >
                View Order Details {">"}{" "}
              </Link>
            </p>
          )}
          {orderStatus === "success" && (
            <div>
              <div className='Order-details rounded-3 '>
                <div className=' Order-details-box ps-4  p-3  border-md-2'>
                  <p className='mb-md-2 mb-1'>Order Details</p>
                  <p className='mb-0'>{allDetails?.order_number} </p>
                </div>
                <div className=' Order-details-box border-md-0 ps-4  p-3  border-md-2'>
                  <p className='mb-md-2 mb-1'>Expected Delivery Date :</p>
                  <p className='mb-0'>{allDetails?.exp_del_date} </p>
                </div>
                <div className='Order-details-box ps-4  p-3  border-md-2'>
                  <p className='mb-md-2 mb-1'>Email :</p>
                  <p className='mb-0'>{allDetails?.email}</p>
                </div>
                <div className='ps-4  p-3  border-md-2'>
                  <p className='mb-md-2 mb-1'>Total Price :</p>
                  <p className='mb-0'>${allDetails?.total_amount}</p>
                </div>
              </div>
            </div>
          )}

          <div>
            <Link
              to={"/"}
              className='text-decoration-none text-white'
            >
              {" "}
              <button className='btn form-control py-2'>
                CONTINUE SHOPPING
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default OrderConfirmationStatus;
