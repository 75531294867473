import { API_URL } from "api/apiUrls";
import axios from "axios";
import { Seo } from "components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ForgotPasswordOTPPage = () => {
  const [email, setEmail] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const [emailError, setEmailError] = useState(""); // New state for email validation error

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if email is empty
    if (!email) {
      setEmailError("Please enter your email");
      return;
    }

    sessionStorage.setItem("forgotEmail", email);
    try {
      const forgotpwdEmail = {
        email: email,
      };

      const response = await axios.post(API_URL.forgotPassword, forgotpwdEmail);
      setResponseMsg(response.data?.message);
      navigate("/otp/resetotpverify/");
    } catch (error) {
      setResponseMsg(error.response.data?.message);
      setTimeout(() => {
        setResponseMsg("");
      }, 3000);
    }
  };

  return (
    <div className="forgot-background">
       <Seo
        metaTitle='Forgot Password'
        metaDescription='Get in touch today!, Need any help please contact us '
      />
     <form  onSubmit={handleSubmit}>
     <div className="forgot-password-otp-page">
        <h1 className="forgot-text">Forgot Password</h1>
        <p>We will send a Verification Code to Your Email Address.</p>
        {responseMsg && (
          <div className="alert alert-primary">{responseMsg}</div>
        )}
        <div className="email-input">
          <label htmlFor="email">Email ID:</label>
          <input
            type="email"
            id="email"
            placeholder="Enter your email"
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError("");
            }}
            required
          />
          {emailError && <div className="error">{emailError}</div>}
        </div>
        <button type="submit">Submit</button>
      </div>
     </form>
    </div>
  );
};

export default ForgotPasswordOTPPage;
