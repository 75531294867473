import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./UsePagination";
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className='d-flex justify-content-end'>
      <ul
        className={classnames("pagination-container", {
          [className]: className,
        })}
      >
        <li
          className={classnames("pagination-item", {
            disabled: currentPage === 1,
          })}
          onClick={onPrevious}
        >
          <div className='arrow-box'>
            <div className='arrow left' />
          </div>
        </li>
        {paginationRange &&
          paginationRange.length > 0 &&
          paginationRange.map((pageNumber) => {
            if (pageNumber === DOTS) {
              return <li className='pagination-item dots'>&#8230;</li>;
            }

            return (
              <li
                className={classnames("pagination-item numbers", {
                  selected: pageNumber === currentPage,
                })}
                onClick={() => onPageChange(pageNumber)}
              >
                {pageNumber}
              </li>
            );
          })}
        <li
          className={classnames("pagination-item", {
            disabled: currentPage === lastPage,
          })}
          onClick={onNext}
        >
          <div className='arrow-box'>
            <div className='arrow right' />
          </div>
        </li>
      </ul>
    </div>
  );
};

export { Pagination };
