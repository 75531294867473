import React, {useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import Account from "assets/icons/account.svg";
import Wishlist from "assets/icons/wishlist.svg";
import Cart from "assets/icons/Cart.svg";
import { headerIcons } from "static/data";
import { dropDownIcons } from "static/data";
import MyOrders from "assets/icons/bag-check-svgrepo-com.svg";
import Support from "assets/icons/support-svgrepo-com.svg";
import Logout from "assets/icons/logout-svgrepo-com.svg";
import { routes } from "routes/navigation";

function NavIcons({ cartItems }) {
  
  const navigate = useNavigate();
  
  const handleLogout = () => {
    sessionStorage.removeItem("accessToken");
    navigate("/")
    window.location.reload(true);
  };

  const loggedInName = sessionStorage.getItem("loggedInUser");
  const loggedInEmail = sessionStorage.getItem("loggedInEmail");


  return (
    <>
      <div>
        <div className='header-mobile-icons'>
          {
            <div className='header-icons-link'>
              <div className='account-hover  p-lg-2'>
                <div
                  className='position-relative'
                  data-bs-toggle='dropdown'
                >
                  <div>
                    <img
                      className='d-block m-auto'
                      src={Account}
                      alt='Account'
                    />
                  </div>
                  <p className='mb-0'>Account</p>
                </div>
                {!sessionStorage.getItem("accessToken") ? (
                  <ul className='dropdown-menu LoginAccountPopup p-4'>
                    <li>
                      <p className=' mb-0 text-center '>Welcome !!!</p>
                      <Link
                        className='w-100 m-auto d-flex justify-content-center text-decoration-none'
                        to='/login'
                      >
                        <div className='btn m-auto my-2'> SIGN IN</div>
                      </Link>
                      <p className='mb-0'>Don't have an account</p>
                      <Link
                        to='/register'
                        className='d-block text-center'
                      >
                        Register Now
                      </Link>
                    </li>
                  </ul>
                ) : (
                  <ul className='dropdown-menu'>
                    <li>
                      <Link
                        className='dropdown-item'
                        to='/'
                      >
                        {loggedInName}
                        <br />
                        <p className='userEmail mb-0 text-truncate'>
                          {loggedInEmail}
                        </p>
                      </Link>
                    </li>
                    {dropDownIcons && dropDownIcons.length > 0 && dropDownIcons?.map((item) => {
                      const { link, icon } = item;
                      let Icon;
                      switch (icon) {
                        case "Profile":
                          Icon = Account;
                          break;
                        case "My Orders":
                          Icon = MyOrders;
                          break;
                        case "Support":
                          Icon = Support;
                          break;
                        case "Logout":
                          Icon = Logout;
                          break;
                        default:
                          break;
                      }
                      return (
                        <React.Fragment key={icon}>
                          <li>
                            {icon === "Logout" && (
                              <hr className='dropdown-divider' />
                            )}
                            {icon === "Logout" ? (
                              <Link
                                className='dropdown-item py-1 text-decoration-none text-gray pe-md-5 '
                                onClick={handleLogout}
                                to={link}
                              >
                                <span>
                                  <img
                                    src={Icon}
                                    alt={Icon}
                                  />
                                </span>
                                <span>{icon}</span>
                              </Link>
                            ) : (
                              <Link
                                className='dropdown-item py-1 text-decoration-none text-gray pe-md-5 '
                                to={link}
                              >
                                <span>
                                  <img
                                    src={Icon}
                                    alt={Icon}
                                  />
                                </span>
                                <span>{icon}</span>
                              </Link>
                            )}
                          </li>
                        </React.Fragment>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          }
          {headerIcons && headerIcons.length > 0 && headerIcons.map((item) => {
            const { link, icon } = item;
            let Icon;
            switch (icon) {
              case "wishlist":
                Icon = Wishlist;
                break;
              case "Cart":
                Icon = Cart;
                break;
              default:
                break;
            }
            return (
              <a
                href={link}
                key={icon}
                className='header-icons-link'
              >
                <div className='d-flex justify-content-center align-items-end'>
                  <div className=''>
                    <div className='p-md-3 p-lg-2 '>
                      <Link
                        to={link}
                        className='text-decoration-none'
                      >
                        <div className='position-relative'>
                          <img
                            className='d-block m-auto'
                            src={Icon}
                            alt={icon}
                          />
                          {icon === "Cart" && (
                            <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                              {cartItems}
                            </span>
                          )}
                        </div>
                        <p className='mb-0'>{icon}</p>
                      </Link>
                    </div>
                    {/* )
                    } */}
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </>
  );
}

export { NavIcons };
