import { baseURL } from "api/apiUrls";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EyeOpenIcon } from '../assets/icons/eye-icon-show.svg';
import { ReactComponent as EyeClosedIcon } from '../assets/icons/eye-icon.svg'
import { Seo } from "components";

const ResetPasswordForm = () => {
  const [newPassword, setNewPassword] = useState("");
  const [reenterPassword, setReenterPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showReenterPassword, setShowReenterPassword] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setError("");
  };

  const handleReenterPasswordChange = (e) => {
    setReenterPassword(e.target.value);
    setError("");
  };

  const navigate = useNavigate();
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== reenterPassword) {
      setError("Passwords do not match");
    } else if (newPassword.length < 8 && reenterPassword.length < 8) {
      setError("Password must be at least 8 characters long.");
    } else {
      try {
        const resetpwdData = {
          password: newPassword,
          password2: reenterPassword,
        };
        const resetToken = sessionStorage.getItem("resetOtpToken");
        const resetOTPUID = sessionStorage.getItem("resetOtpuid");
        const response = await axios.post(
          `${baseURL}/Users/resetpassword/?uid=${resetOTPUID}&token=${resetToken}`,
          resetpwdData,
        );
        setSuccess(response.data?.message);
        setTimeout(() => {
          setSuccess("");
          navigate("/login");
        }, 3000);
      } catch (error) {
        setSuccess(error.response.data?.message);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      }
    }
  };

  return (
    <div className='password-container'>
       <Seo
        metaTitle='Reset Password'
        metaDescription='Get in touch today!, Need any help please contact us '
      />
      <div className='reset-container'>
        <form
          className='reset-password-form'
          onSubmit={handleFormSubmit}
        >
          <h2 className='form-heading mb-0 pb-1'>Reset Password</h2>
          {success && <div className='alert alert-primary'>{success}</div>}
          <div className='form-group'>
            <label htmlFor='newPassword'>New Password</label>
            <div className='password-input-container'>
              <input
                type={showNewPassword ? "text" : "password"}
                id='newPassword'
                name='newPassword'
                value={newPassword}
                onChange={handleNewPasswordChange}
                required
              />
              <button
                type='button'
                className='password-toggle-button'
                onClick={() => setShowNewPassword((prev) => !prev)}
              >
                {showNewPassword ? <EyeClosedIcon />  : <EyeOpenIcon /> }
              </button>
            </div>
          
          </div>
          <div className='form-group'>
            <label htmlFor='reenterPassword'>Re-enter Password</label>
            <div className='password-input-container'>
              <input
                type={showReenterPassword ? "text" : "password"}
                id='reenterPassword'
                name='reenterPassword'
                value={reenterPassword}
                onChange={handleReenterPasswordChange}
                required
              />
              <button
                type='button'
                className='password-toggle-button'
                onClick={() => setShowReenterPassword((prev) => !prev)}
              >
                {showReenterPassword ? <EyeClosedIcon />  : <EyeOpenIcon />}
              </button>
            </div>
          </div>
          {error && <p className='error-msg'>{error}</p>}
          <button type='submit'>Submit</button>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
