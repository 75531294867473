import React, { useState, useEffect } from "react";
import { Addresses, Passwords, PersonalInformation, Seo } from "components";
import axios from "axios";
import { API_URL } from "api/apiUrls";

function UserProfile({ token }) {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const tabLabels = ["Personal Information", "Password", "Addresses"];
  const [userAddress, setUserAddress] = useState([]);

  useEffect(() => {
    const getAddress = async () => {
      try {
        const response = await axios.get(API_URL.userAddress, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        setUserAddress(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAddress();
  }, [token]);

  const tabContents = [
    <PersonalInformation token={token} />,
    <Passwords token={token} />,
    <Addresses
      token={token}
      userAddress={userAddress}
    />,
  ];

  return (
    <div className='container'>
      <Seo
        metaTitle='My Profile'
        metaDescription='Get in touch today!, Need any help please contact us '
      />
      <p className='fw-bold'>My Account</p>
      <div className='tab-buttons '>
        {tabLabels && tabLabels.length > 0 && tabLabels.map((label, index) => (
          <button
            key={index}
            className={activeTab === index ? "active" : "fw-bold"}
            onClick={() => handleTabClick(index)}
          >
            {label}
          </button>
        ))}
      </div>
      <div className='tab-content'>
        <p>{tabContents[activeTab]}</p>
      </div>
    </div>
  );
}

export default UserProfile;
