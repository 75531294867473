import React, { useState } from "react";
import eyeopen from "assets/icons/eye-icon-show.svg";
import eyeclose from "assets/icons/eye-icon.svg";
import axios from "axios";
import { API_URL } from "api/apiUrls";

const Passwords = ({ token }) => {
  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    reenterPassword: "",
  });

  const [visibility, setVisibility] = useState({
    currentPassword: false,
    newPassword: false,
    reenterPassword: false,
  });

  const [errors, setErrors] = useState({
    currentPassword: "",
    newPassword: "",
    reenterPassword: "",
  });

  const toggleVisibility = (field) => {
    setVisibility({
      ...visibility,
      [field]: !visibility[field],
    });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setPasswords({
      ...passwords,
      [id]: value,
    });
  };
  const [cartMsg, setCartMsg] = useState("");
  const [showCartMsg, setShowCartMsg] = useState(false);

  // change password
  const changePassword = async () => {
    const updatePwd = {
      current_password: passwords.currentPassword,
      password: passwords.newPassword,
      password2: passwords.reenterPassword,
    };
    try {
      const response = await axios.post(API_URL.userUpdatePassword, updatePwd, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setCartMsg(response.data?.message);
      setShowCartMsg(true);
    } catch (error) {
      setCartMsg(error.response.data?.message);
      setShowCartMsg(true);
    }
  };

  const handleClose = () => {
    window.location.reload(true);
    setShowCartMsg(false);
  };

  const handleSubmit = () => {
    const newErrors = {
      currentPassword: "",
      newPassword: "",
      reenterPassword: "",
    };

    if (passwords.currentPassword === "") {
      newErrors.currentPassword = "Current Password is required";
    }

    if (passwords.newPassword === "") {
      newErrors.newPassword = "New Password is required";
    } else if (passwords.newPassword.length < 8) {
      newErrors.newPassword = "New Password should be at least 8 characters";
    }

    if (passwords.reenterPassword === "") {
      newErrors.reenterPassword = "Re-Enter Password is required";
    } else if (passwords.reenterPassword !== passwords.newPassword) {
      newErrors.reenterPassword = "Passwords do not match";
    }

    setErrors(newErrors);

    // Handle form submission logic here
    if (
      !newErrors.currentPassword &&
      !newErrors.newPassword &&
      !newErrors.reenterPassword
    ) {
      // Passwords are valid, submit the form or perform any other actions
      changePassword();
    }
  };

  return (
    <div className='password'>
      <div className='row'>
        <div className='col-lg-7'>
          <div className='row'>
            <div className='col-12 mt-3'>
              <label htmlFor='currentPassword'>Current Password</label>
              <div className='input-group'>
                <input
                  type={visibility.currentPassword ? "text" : "password"}
                  className='form-control'
                  id='currentPassword'
                  value={passwords.currentPassword}
                  onChange={handleChange}
                />
                <button
                  className='btn btn-toggle'
                  type='button'
                  onClick={() => toggleVisibility("currentPassword")}
                >
                  <img
                    src={visibility.currentPassword ? eyeclose : eyeopen}
                    alt='Toggle Password Visibility'
                  />
                </button>
              </div>
              <div className='error'>{errors.currentPassword}</div>
            </div>
            <div className='col-12 mt-3'>
              <label htmlFor='newPassword'>New Password</label>
              <div className='input-group'>
                <input
                  type={visibility.newPassword ? "text" : "password"}
                  className='form-control'
                  id='newPassword'
                  value={passwords.newPassword}
                  onChange={handleChange}
                />
                <button
                  className='btn btn-toggle'
                  type='button'
                  onClick={() => toggleVisibility("newPassword")}
                >
                  <img
                    src={visibility.newPassword ? eyeclose : eyeopen}
                    alt='Toggle Password Visibility'
                  />
                </button>
              </div>
              <div className='error'>{errors.newPassword}</div>
            </div>
            <div className='col-12 mt-3'>
              <label htmlFor='reenterPassword'>Re-Enter Password</label>
              <div className='input-group'>
                <input
                  type={visibility.reenterPassword ? "text" : "password"}
                  className='form-control'
                  id='reenterPassword'
                  value={passwords.reenterPassword}
                  onChange={handleChange}
                />
                <button
                  className='btn btn-toggle'
                  type='button'
                  onClick={() => toggleVisibility("reenterPassword")}
                >
                  <img
                    src={visibility.reenterPassword ? eyeclose : eyeopen}
                    alt='Toggle Password Visibility'
                  />
                </button>
              </div>
              <div className='error'>{errors.reenterPassword}</div>
            </div>
            <div className='d-flex'>
              <div className='col-6 mb-3 me-2 mt-4'>
                <button className='btn-cancel'>Cancel</button>
              </div>
              <div className='col-6 mb-3 mt-4'>
                <button
                  className='btn-save'
                  onClick={handleSubmit}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showCartMsg && (
        <div className='cart-popup text-center'>
          <div className='cart-message'>{cartMsg}</div>
          <button
            className='close-cart-popup'
            onClick={() => handleClose()}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export { Passwords };
