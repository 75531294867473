import React from "react";

import {
  Banner,
  TopCategories,
  HomeGridSection,
  CalltoAction,
  ProductCard,
  Seo,
} from "components";  

const Home = ({ headerNav }) => {
  const { banner_images, best_seller, section_images, top_category } =
    headerNav;
  return (
    <div className="home-wrapper overflow-hidden">
       <Seo
        metaTitle='Home'
        metaDescription='Get in touch today!, Need any help please contact us '
      />
      <Banner data={banner_images?.slice(0, 3)} />
      <div className="container">
        <TopCategories data={top_category?.slice(0, 8)} />
        {section_images && section_images.length > 0 && (
          <HomeGridSection data={section_images?.slice(0, 4)} />
        )}
        <div>
          <div className="section-head my-3">
            <h2 className="fw-semibold  pb-md-auto pb-0">Best Sellers</h2>
          </div>
          <div className="row position-relative">
            <ProductCard data={best_seller?.slice(0, 8)} />
          </div>
        </div>
      </div>
      <CalltoAction />
    </div>
  );
};

export default Home;
