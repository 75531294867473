import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MobileMenu } from "components/MobileMenu";
import { GlobalSearch, NavIcons } from "components";
import humburger from "assets/icons/menu-alt-01-svgrepo-com.svg";
import Logo from "assets/icons/BB-Logo.png";

const MobileHeader = ({ headerNav, cartItems }) => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className="bg-white pb-4">
      <div className='header-mobile-container'>
        <div
          className='humburger rounded-circle'
          onClick={() => setOpenMenu(!openMenu)}
        >
          <img
            src={humburger}
            alt='menu'
          />
        </div>
        <div>
          <Link
            to='/'
            className='header-mobile-logo'
          >
            <img
              src={Logo}
              alt=''
            />
          </Link>
        </div>
        <NavIcons cartItems={cartItems} />
      </div>
      <div className='text-center'>
        <GlobalSearch />
      </div>
      <MobileMenu
        openMenu={openMenu}
        headerNav={headerNav}
        setOpenMenu={setOpenMenu}
      />
    </div>
  );
};

export { MobileHeader };
