import React from "react";
import { Helmet } from "react-helmet";

const Seo = ({ metaTitle, metaDescription }) => {
  return (
    <Helmet>
      <title>{metaTitle} | ByteBazaar</title>
      <meta
        name='title'
        content={metaTitle}
      />
      <meta
        name='description'
        content={metaDescription}
      />
    </Helmet>
  );
};

export { Seo };
