import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "pages/Login";
import Home from "pages/Home";
import Contact from "pages/Contact";
import { Header, Footer } from "components";
import { routes } from "./navigation";
import Register from "pages/Register";
import axios from "axios";
import { API_URL, baseURL } from "api/apiUrls";
import ForgotPasswordOTPPage from "pages/ForgotPasswordOTPPage";
import OtpPage from "templates/OtpPage";
import ProductCategoryDetails from "templates/product-category-details";
import SubCategoryDetails from "templates/subcategory-details";
import SingleProductDetails from "templates/single-product-details";
import MyOrdersPage from "pages/MyOrdersPage";
import Cart from "pages/Cart";
import ResetPasswordForm from "pages/ResetPasswordForm";
import GuestSignIn from "pages/GuestSignIn";
import GuestUserAddress from "pages/GuestUserAddress";
import MyOrdersViewPage from "templates/myorders-view-page";
import OrderConfirmationStatus from "templates/OrderConfirmationStatus";
import RegisteredUserAddress from "pages/RegisteredUserAddress";
import PageNotFound from "pages/PageNotFound";
import UserProfile from "pages/UserProfile";
import Wishlist from "pages/Wishlist";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Termsofuse from "pages/Termsofuse";

const RoutesComponent = () => {
  const [headerNav, setHeaderNav] = useState([]);
  const [products, setProducts] = useState([]);

  const sessionID = sessionStorage.getItem("sessionID");
  const token = sessionStorage.getItem("accessToken");
  const [cartItem, setCartItems] = useState([]);
  //
  useEffect(() => {
    if (token) {
      sessionStorage.removeItem("sessionID");
    } else {
      sessionStorage.removeItem("accessToken");
    }
  }, [token, sessionID]);

  const cartID = localStorage.getItem("cartID");
  useEffect(() => {
    const getCart = async () => {
      try {
        if (token) {
          const response = await axios.get(
            `${API_URL.getcartUser}?session_id=&cart_id=${cartID}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            },
          );
          setCartItems(response.data.data);
        }
        if (sessionID) {
          const response = await axios.get(
            `${baseURL}/Orders/getcartitem/?session_id=${sessionID}&cart_id=${cartID}`,
          );
          setCartItems(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCart();
  }, [token, sessionID, cartID]);

  useEffect(() => {
    const getAllData = async () => {
      try {
        if (token) {
          const response = await axios.get(API_URL.headerCategory, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
          setHeaderNav(response.data.data);
        } else {
          const response = await axios.get(API_URL.headerCategory);
          setHeaderNav(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllData();
  }, []);

  return (
    <div className='App'>
      <BrowserRouter>
        <Header
          headerNav={headerNav}
          cartItems={cartItem?.length}
        />
        <Routes>
          <Route
            path='/'
            element={<Home headerNav={headerNav} />}
          />
          <Route
            path={routes.login}
            element={<Login />}
          />
          <Route
            path={routes.contactUs}
            element={<Contact />}
          />
          <Route
            path={routes.guestuseraddress}
            element={<GuestUserAddress />}
          />
          <Route
            path={routes.register}
            element={<Register />}
          />{" "}
          <Route
            path={routes.activateOTP}
            element={<OtpPage />}
          />
          <Route
            path={routes.forgotPassword}
            element={<ForgotPasswordOTPPage />}
          />
          <Route
            path={routes.registeruseraddress}
            element={<RegisteredUserAddress />}
          />
          <Route
            path={routes.guestsignin}
            element={<GuestSignIn />}
          />
          <Route
            path={routes.resetpassword}
            element={<ResetPasswordForm />}
          />
          <Route
            path={`/:category`}
            element={
              <ProductCategoryDetails
                headerNav={headerNav}
                products={products}
                token={token}
                setProducts={setProducts}
              />
            }
          />
          <Route
            path={`/:category/:subcategory`}
            element={
              <SubCategoryDetails
                headerNav={headerNav}
                products={products}
                token={token}
                setProducts={setProducts}
              />
            }
          />
          <Route
            path={`/:category/:subcategory/:productId`}
            element={<SingleProductDetails token={token} />}
          />
          <Route
            path={`/otp/:otpType`}
            element={<OtpPage />}
          />
          <Route
            path={routes.myOrders}
            element={<MyOrdersPage token={token} />}
          />
          <Route
            path={`/my-orders/:orderId`}
            element={<MyOrdersViewPage token={token} />}
          />
          <Route
            path={routes.cartPage}
            element={<Cart cartItem={cartItem} />}
          />
          <Route
            path={`/order-status/:orderStatus`}
            element={<OrderConfirmationStatus />}
          />
          <Route
            path='*'
            element={<PageNotFound />}
          />
          <Route
            path='/page-not-found'
            element={<PageNotFound />}
          />
          <Route
            path={routes.profile}
            element={<UserProfile token={token} />}
          />
          <Route
            path={routes.wishlist}
            element={<Wishlist token={token} />}
          />
           <Route
            path={routes.privacyPolicy}
            element={<PrivacyPolicy token={token} />}
          />
           <Route
            path={routes.termsofuse}
            element={<Termsofuse token={token} />}
          />
        </Routes>
        <Footer headerNav={headerNav} />
      </BrowserRouter>
    </div>
  );
};

export default RoutesComponent;
