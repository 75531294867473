import React, { useState } from "react";
import { Link } from "react-router-dom";

const MobileMenu = ({ openMenu, headerNav, setOpenMenu }) => {
  const categoryNav = headerNav.category_subCategory;
  const [categoryRoute, setCategoryRoute] = useState("");

  return (
    <div
      className={
        openMenu ? "header-mobile-wrapper open" : "header-mobile-wrapper hide"
      }
    >
      <div className='header-mobile-links-container w-100'>
        {categoryNav &&
          categoryNav.length > 0 &&
          categoryNav.map((item) => {
            const { name, subcategory } = item;
            return (
              <div key={name}>
                <div
                  className='dropdown header-dropdown'
                  onClick={(e) => setCategoryRoute(e.target.innerText)}
                >
                  {subcategory.length > 0 ? (
                    <Link
                      className=' header-dropdown-btn text-white dropdown-toggle border-0 d-block w-100 text-start'
                      to={`/${name.split(" ").join("-").toLowerCase()}`}
                      role='button'
                      id='dropdownMenuLink'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      {name}
                    </Link>
                  ) : (
                    <Link
                      to={`/${name.split(" ").join("-").toLowerCase()}`}
                      className='header-link  text-white'
                      onClick={() => setOpenMenu(false)}
                    >
                      {name}
                    </Link>
                  )}
                  <ul
                    className={"dropdown-menu"}
                    id='mobile-menu-dropdown'
                    aria-labelledby='dropdownMenuLink'
                  >
                    {subcategory &&
                      subcategory.length > 0 &&
                      subcategory.map((item) => {
                        const subcategoryRoute = `${categoryRoute}/${item.name}`;

                        return (
                          <React.Fragment key={item.name} >
                            {item.is_published && (
                              <li >
                                <Link
                                  className='dropdown-item'
                                  to={subcategoryRoute
                                    .split(" ")
                                    .join("-")
                                    .toLowerCase()}
                                  onClick={() => setOpenMenu(false)}
                                >
                                  {item.name}
                                </Link>
                              </li>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </ul>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export { MobileMenu };
