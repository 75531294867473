import React, { useEffect } from "react";
import { MyOrders, Seo, SortingDropdown } from "components";
import { baseURL } from "api/apiUrls";
import axios from "axios";
import { useState } from "react";

function MyOrdersPage({ token }) {
  const [getOrderList, setGetOrdersList] = useState([]);
  const [error, setError] = useState("");
  const [sorted, setSorted] = useState("newest");

  const sortOptions = [
    { value: "newest", view: "Newest" },
    { value: "oldest", view: "Oldest" },
  ];
  
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(
        `${baseURL}/Users/userorderlist/?page=1&item=100&&sort=${sorted}`,
        config
      )
      .then((response) => {
        setGetOrdersList(response.data.data);
      })
      .catch((error) => {
        setError(error.response.data?.message);
      });
  }, [token, sorted]);

  return (
    <div className="container My-Orders-page">
       <Seo
        metaTitle='My Orders'
        metaDescription='Get in touch today!, Need any help please contact us '
      />
      <SortingDropdown
        setSorted={setSorted}
        products={getOrderList}
        sortOptions={sortOptions}
        title="Orders"
      />
      {error ? <h2 className="text-center my-5 fw-semibold"> {error} </h2> : <MyOrders data={getOrderList} />}
    </div>
  );
}

export default MyOrdersPage;
