import React, { useState } from "react";
import { Link } from "react-router-dom";

const Sidebar = ({
  data,
  minPrice,
  maxPrice,
  setMaxPrice,
  setMinPrice,
  brands,
  checked,
  setChecked,
  setFilter,
}) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  //handle checkbox
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  return (
    <div>
      <div className={`sidebar ${sidebarVisible ? "show" : "hide"}`}>
        <button
          className=' filter-btn'
          onClick={toggleSidebar}
        >
          <svg
            viewBox='0 0 100 80'
            fill='grey'
            width='40'
            height=''
          >
            <rect
              width='100'
              height='12'
            ></rect>
            <rect
              y='30'
              width='90'
              height='12'
            ></rect>
            <rect
              y='60'
              width='80'
              height='12'
            ></rect>
          </svg>{" "}
          <p>filters</p>
        </button>
        <div className='filterbar-wrapper'>
          <div className='collapse-group'>
            <button
              className='btn btn-p fw-semibold'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#categoryCollapse'
              aria-expanded='true'
              aria-controls='categoryCollapse'
            >
              All Categories
            </button>
            <div
              className='collapse show'
              id='categoryCollapse'
            >
              <div className='collapse-group border-bottom'>
                {data &&
                  data.length > 0 &&
                  data.map((item) => {
                    const { name, subcategory } = item;
                    const categoryName = name.split(" ").join("-");
                    return (
                      <div key={name}>
                        {subcategory.length > 0 ? (
                          <button
                            className='btn accordion-button ps-5 py-2 text-truncate'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target={`#${categoryName}`}
                            aria-expanded='true'
                            aria-controls='categoryCollapse'
                          >
                            {name}
                          </button>
                        ) : (
                          <div
                            className='ps-5 py-2 text-truncate text-white'
                            style={{ background: "#2088B9" }}
                          >
                            <Link
                              to={`/${categoryName.toLowerCase()}`}
                              className='text-decoration-none text-white'
                            >
                              {name}
                            </Link>
                          </div>
                        )}
                        <div className='sidebar-collapse-list'>
                          {subcategory &&
                            subcategory.length > 0 &&
                            subcategory.map((item) => {
                              const { name, is_published } = item;
                              return (
                                <React.Fragment>
                                  {is_published && (
                                    <Link
                                      key={name}
                                      className='collapse ps-5 py-1  text-truncate '
                                      id={categoryName}
                                      to={`/${categoryName}/${name
                                        .split(" ")
                                        .join("-")
                                        .toLowerCase()}`}
                                      onClick={() =>
                                        setTimeout(() => {
                                          setSidebarVisible(false);
                                        }, 300)
                                      }
                                    >
                                      {name} {">"}
                                    </Link>
                                  )}
                                </React.Fragment>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          {brands && brands.length > 0 && (
            <div className='collapse-group  border-bottom'>
              <button
                className='btn btn-p'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#brandCollapse'
                aria-expanded='false'
                aria-controls='brandCollapse'
              >
                Brands
              </button>
              <div
                className='collapse show'
                id='brandCollapse'
              >
                <div className='ps-5 my-1'>
                  {brands &&
                    brands.length > 0 &&
                    brands.map((item) => {
                      const { brand } = item;
                      return (
                        <div key={brand}>
                          <input
                            type='checkbox'
                            value={brand}
                            onChange={handleCheck}
                          />
                          <span>{brand}</span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
          <div className='collapse-group  border-bottom'>
            <button
              className='btn btn-p'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#priceRangeCollapse'
              aria-expanded='false'
              aria-controls='priceRangeCollapse'
            >
              Price Range
            </button>
            <div
              className='collapse show'
              id='priceRangeCollapse'
            >
              <div className='price-range'>
                <div className='d-flex'>
                  <p>Min Price:</p>
                  <input
                    type='number'
                    placeholder='Min'
                    value={minPrice}
                    onChange={(e) => setMinPrice(e.target.value)}
                  />
                </div>
                <div className='d-flex'>
                  <p>Max Price:</p>
                  <input
                    type='number'
                    placeholder='Max'
                    value={maxPrice}
                    onChange={(e) => setMaxPrice(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <button onClick={() => setFilter(true)}>Apply Changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Sidebar };
