export const baseURL = process.env.REACT_APP_BASE_URL;

export const API_URL = {
  headerCategory: `${baseURL}/Users/home/`,
  register: `${baseURL}/Users/registration/`,
  login: `${baseURL}/Users/login/`,
  resendOTP: `${baseURL}/Users/resentotp/`,
  contact: `${baseURL}/Users/contactus/`,
  forgotPassword: `${baseURL}/Users/sendresetpasswordemail/`,
  resetPassword: `${baseURL}/Users/resetpassword/`,
  addToCart: `${baseURL}/Orders/addcartitem/`,
  getcartUser: `${baseURL}/Orders/getcartitem/`,
  googleLogin: `${baseURL}/Users/login_google/`,
  getCart: `${baseURL}/Orders/getcartitem/`,
  facebookLogin: `${baseURL}/Users/login_facebook/`,
  userAddress: `${baseURL}/Users/addaddress/`,
  resetresndOTP: `${baseURL}/Users/sendresetpasswordemail/`,
  userCheckout: `${baseURL}/Orders/cartcheckout/`,
  userFinalCheckout: `${baseURL}/Orders/finalcheckout/`,
  usergetInfo: `${baseURL}/Users/getuserprofile/`,
  userUpdateInfo: `${baseURL}/Users/updateuserprofile/`,
  userUpdatePassword: `${baseURL}/Users/changepassword/`,
  usergetAddress: `${baseURL}/Users/getaddress/`,
  getWishList: `${baseURL}/Users/getwishlist/`,
  getReviews: `${baseURL}/Users/getrating/?review_id=`,
  addWishList: `${baseURL}/Users/addwishlist/`,
};
