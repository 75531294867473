import React from "react";
import { Link } from "react-router-dom";
import Login from "./Login";
import { Seo } from "components";


function GuestSignIn() {
  return (
    <div className='GuestSignIn-page container'>
       <Seo
        metaTitle='Guest Login'
        metaDescription='Get in touch today!, Need any help please contact us '
      />
      <div className='row GuestSignIn-page-wrapper'>
        <div className=' col-lg-7 border-right d-flex justify-content-center align-items-center'>
          <div className=''>
            <Login />
          </div>
        </div>
        <div className='col-lg-5  guest-checkout-sec d-flex justify-content-center align-items-center'>
          <div className=''>
            <h1 className='text-center'>
              Continue to checkout without Signing In
            </h1>
            <p className='text-center'>You can also checkout without Login</p>
            <Link to='/guest-address'>
              <button className='btn form-control'>
                Continue As Guest User
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuestSignIn;
