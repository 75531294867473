import React, { useEffect, useState } from "react";
import { baseURL } from "api/apiUrls";
import searchIcon from "assets/icons/search-alt-svgrepo-com.svg";
import axios from "axios";
import { Link } from "react-router-dom";
import { truncate } from "utils/index";

function GlobalSearch() {
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);
  const [showSearch, setShowSearch] = useState(false);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    if (searchTerm !== " " && searchTerm.length > 1) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }
  };

  const handleSearchClick = async (id) => {
    try {
      const response = await axios.get(
        `${baseURL}/Users/search/?page=1&item=100&search=${id}`,
      );

      setProducts(response?.data?.data);
    } catch (error) {
      console.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    handleSearchClick(searchTerm);
  }, [searchTerm]);

  return (
    <>
      <form className='input-group mb-md-0 px-4 px-lg-0 py-1 global-search '>
        <input
          type='text'
          className='form-control postion-relative'
          placeholder='Search for Products'
          aria-label='Username'
          aria-describedby='basic-addon1'
          value={searchTerm}
          onChange={handleInputChange}
        />
        <button
          className='input-group-text'
          id='basic-addon1'
          onClick={handleSearchClick}
        >
          <img
            src={searchIcon}
            alt=''
          />
        </button>

        <div className={showSearch ? "search-Align show" : "search-Align"}>
          {showSearch &&
            searchTerm &&
            products &&
            products.length > 0 &&
            products.slice(0,15).map((item) => {
              const link = `${item.category_name}/${item.sub_category_name}`;
              return (
                <Link
                  to={link.split(" ").join("-").toLowerCase()}
                  onClick={() => setShowSearch(false)}
                  key={item.name}
                >
                  <p>{truncate(item.name, 32)}</p>
                  <div className='item-in-category'>
                    {item.sub_category_name} in {item.category_name}
                  </div>
                </Link>
              );
            })}
        </div>
      </form>
    </>
  );
}

export { GlobalSearch };
