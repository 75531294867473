import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL, baseURL } from "api/apiUrls";
import axios from "axios";
import { removeDuplicates } from "utils";
import { Seo } from "components";

const Cart = ({ cartItem }) => {
  const [cartItems, setCartItems] = useState([]);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(
    "Choose The Delivery Location",
  );

  useEffect(() => {
    // Calculate total amount based on cart items
    const calculateTotalAmount = () => {
      const subtotal = cartItems.reduce(
        (sum, item) => sum + Number(item.unit_price * item.quantity),
        0,
      );

      setSubTotalAmount(subtotal);
    };

    calculateTotalAmount();
  }, [cartItems]);

  //update cart
  const cartID = localStorage.getItem("cartID");

  const updateCart = async (id, quantity, price) => {
    try {
      const updateCartData = {
        product: `${id}`,
        quantity: quantity,
        unit_price: price,
        sub_total: quantity * price,
      };
      if (sessionID) {
        const response = await axios.put(
          `${baseURL}/Orders/updatecartitem/?session_id=${sessionID}&cart_id=${cartID}`,
          updateCartData,
        );
        setCartMsg(response.data?.message);
        setShowCartMsg(true);
      }

      if (token) {
        const response = await axios.put(
          `${baseURL}/Orders/updatecartitem/?session_id=&cart_id=${cartID}`,
          updateCartData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        );
        setCartMsg(response.data?.message);
        setShowCartMsg(true);
      }
    } catch (error) {
      setCartMsg(error.response.data.message);
      setShowCartMsg(true);
    }
  };

  const handleIncrease = (index) => {
    const updatedItems = [...cartItems];
    const updateQuantity = (updatedItems[index].quantity += 1);
    const updateProduct = updatedItems[index].product_id;
    const updatePrice = updatedItems[index].unit_price;
    updateCart(updateProduct, updateQuantity, updatePrice);
    setCartItems(updatedItems);
  };

  const deleteCartItem = async (id, type) => {
    const cartDel = {
      cart_item_id: id,
    };
    try {
      if (sessionID) {
        const response = await axios.delete(
          `${baseURL}/Orders/deletecartitem/?session_id=${sessionID}`,
          { data: cartDel },
        );
        setShowCartMsg(true);
        setCartMsg(response.data?.message);
      }
      if (token) {
        const response = await axios.delete(
          `${baseURL}/Orders/deletecartitem/?session_id=${sessionID}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: cartDel,
          },
        );
        setShowCartMsg(true);
        setCartMsg(
          type === "remove" ? response.data?.message : "item move to wishlistt",
        );
      }
    } catch (error) {
      // Handle the error
      setShowCartMsg(true);
      setCartMsg(error.response.data?.message);
    }
  };

  const handleDecrease = (index, id) => {
    if (cartItems[index].quantity > 0) {
      const updatedItems = [...cartItems];
      const updateQuantity = (updatedItems[index].quantity -= 1);
      const updateProduct = updatedItems[index].product_id;
      const updatePrice = updatedItems[index].unit_price;
      if (updateQuantity < 1) {
        deleteCartItem(id);
      } else {
        updateCart(updateProduct, updateQuantity, updatePrice);
      }
      setCartItems(updatedItems);
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    setCartItems(cartItem);
  }, [cartItem]);

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };
  const totalAmount = subTotalAmount + subTotalAmount * 0.15 + 40;

  const sessionID = sessionStorage.getItem("sessionID");
  const token = sessionStorage.getItem("accessToken");

  const [userCart, setUserCart] = useState([]);
  const [cartMsg, setCartMsg] = useState("");
  const [showCartMsg, setShowCartMsg] = useState(false);

  useEffect(() => {
    const selectedProperties = cartItems?.map((obj) => ({
      product_id: `${obj.product_id}`,
      quantity: `${obj.quantity}`,
      unit_price: obj.unit_price,
    }));

    setUserCart((prev) => [...prev, ...selectedProperties]);
  }, [cartItems]);

  const filteredCart = removeDuplicates(userCart, "product_id");

  const gst = Math.ceil(subTotalAmount * 0.15).toFixed(2);

  const handleRoute = async () => {
    const addToCartData = {
      cart_items: filteredCart,
      subtotal_amount: `${subTotalAmount}`,
      gst_amount: `${gst}`,
      shipping_amount: "40",
      total_amount: `${totalAmount}`,
    };
    try {
      if (token) {
        const response = await axios.post(API_URL.userCheckout, addToCartData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        setCartMsg(response.data?.message);
        if (response.data?.message === "Success!!!") {
          sessionStorage.setItem("orderID", response.data.data.order_id);
          navigate("/registered-address");
        }
      } else {
        const response = await axios.post(
          `${baseURL}/Orders/cartcheckout/?session_id=${sessionID}`,
          addToCartData,
        );
        setCartMsg(response.data.message);
        if (response.data?.message === "Success!!!") {
          sessionStorage.setItem("orderID", response.data.data.order_id);
          navigate("/guest-login");
        }
      }
    } catch (err) {
      setCartMsg(err.response.data?.message);
    }
  };

  const handleClose = () => {
    setCartMsg("");
    setShowCartMsg(false);
    window.location.reload(true);
  };

  //wishlist

  const handleWishList = async (product_id, id, type) => {
    const token = sessionStorage.getItem("accessToken");

    if (!token) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        `${API_URL.addWishList}?product_id=${product_id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );
      deleteCartItem(id, type);
      setCartMsg(response.data?.message);
      setShowCartMsg(true);
    } catch (error) {
      setCartMsg(error.response.data?.message);
      setShowCartMsg(true);
    }
  };
  return (
    <div className='container'>
      <Seo
        metaTitle='My Cart'
        metaDescription='Get in touch today!, Need any help please contact us '
      />
      <div className='row '>
        <div className='col-lg-12'>
          <h2 className='mainn-R'>Cart</h2>
        </div>
      </div>
      {cartItems.length > 0 ? (
        <div className='row'>
          <div className='col-lg-8 cart-sec-main'>
            {cartItem &&
              cartItem.length > 0 &&
              cartItems.map((item, index) => {
                const {
                  name,
                  image,
                  product_id,
                  sub_category_name,
                  category_name,
                  unit_price,
                  quantity,
                  id,
                  is_wishlist,
                } = item;
                return (
                  <div
                    className='row main-R'
                    key={index}
                  >
                    <div className='col image-W'>
                      <img
                        src={image}
                        alt={name}
                      />
                    </div>
                    <div className='col'>
                      <h2>{item.name}</h2>
                      <Link
                        to={`/${category_name
                          .toLowerCase()
                          .split(" ")
                          .join("-")}/${sub_category_name
                          .toLowerCase()
                          .split(" ")
                          .join("-")}/${product_id}`}
                      >
                        View Product {" >"}
                      </Link>
                    </div>
                    <div className='col '>
                      <p>Quantity</p>
                      <div className='d-flex justify-content-between quantity-content'>
                        <div>
                          <button
                            className='quantity-btn'
                            onClick={() => handleDecrease(index, id)}
                          >
                            -
                          </button>
                        </div>
                        <div>
                          <h2>{quantity}</h2>
                        </div>
                        <div>
                          <button
                            className='quantity-btn'
                            onClick={() => handleIncrease(index)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='col'>
                      <p>Price</p>
                      <h2>${unit_price}</h2>
                    </div>
                    <div className='d-flex button-RM '>
                      <button
                        className='remove-btn'
                        onClick={() => deleteCartItem(id, "remove")}
                      >
                        Remove
                      </button>
                      {is_wishlist ? (
                        <button
                          className='wishlist-btn'
                          disabled
                        >
                          Already in wishlist
                        </button>
                      ) : (
                        <button
                          className='wishlist-btn'
                          onClick={() => handleWishList(product_id, id, "move")}
                        >
                          Move to wishlist
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            <Link
              to='/'
              className='btn btn-primary mt-3'
            >
              Go back
            </Link>
          </div>
          <div className='col-lg-4  ps-0 ps-md-5'>
            <div className='cart-section '>
              <h3>Shipping Location</h3>
              <select
                className='form-select'
                aria-label='Default select example'
                value={selectedLocation}
                onChange={handleLocationChange}
              >
                <option disabled>Add Delivery Location</option>
                <option value='One'>One</option>
                <option value='Two'>Two</option>
                <option value='Three'>Three</option>
              </select>
              <div>
                <h3>Order Summary</h3>
                <div className='d-flex justify-content-between'>
                  <div>
                    <p>Sub Total:</p>
                    <p>GST(15%):</p>
                    <p>Shipping:</p>
                    <p className='fw-bold'>Total:</p>
                  </div>
                  <div>
                    <p>${subTotalAmount.toFixed(2)}</p>
                    <p className='text-truncate'>${gst}</p>
                    <p>$40.00</p>
                    <p className='fw-bold'>${totalAmount}</p>
                  </div>
                </div>
                <button onClick={() => handleRoute()}>Checkout</button>
              </div>
            </div>
          </div>
          {showCartMsg && (
            <div className='cart-popup text-center'>
              <div className='cart-message'>{cartMsg}</div>
              <button
                className='close-cart-popup'
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className='text-center my-5'>
          <h2>No Items in cart</h2>
          <Link
            to='/'
            className='btn btn-primary mt-3'
          >
            Go back
          </Link>
        </div>
      )}
    </div>
  );
};

export default Cart;
