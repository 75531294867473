import { Seo } from "components";
import React from "react";
import { Termsandconditions } from "static/data";

const Termsofuse = () => {
  return (
    <div className='container'>
        <Seo metaTitle="Terms Condition" metaDescription="Terms" />
      <div className='termsofuse'>
        {Termsandconditions.map((section, index) => (
          <div key={index}>
            <h3 className='termsofuse-text'>{section.title}</h3>
            <p className='termsofuse-para'>{section.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Termsofuse;
