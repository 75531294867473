import React, { useState } from "react";
import { API_URL, baseURL } from "api/apiUrls";
import axios from "axios";

const Addresses = ({ token, userAddress }) => {
  const [showAddress, setShowAddress] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });
  const validatePhoneNumber = (phoneNumber) => {
    const phonePattern = /^[0-9]{10}$/;
    return phonePattern.test(phoneNumber);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [validationErrors, setValidationErrors] = useState({});

  const validateForm = () => {
    const error = {};
    if (!formData.firstName) {
      error.firstName = "First Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(formData.firstName)) {
      error.firstName = "Invalid First Name format (letters only)";
    }
    if (!formData.lastName) {
      error.lastName = "Last Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(formData.lastName)) {
      error.lastName = "Invalid Last Name format (letters only)";
    }
    if (!formData.phone) {
      error.phone = "Phone Number is required";
    } else if (!validatePhoneNumber(formData.phone)) {
      error.phone = "Invalid phone number format (10 digits)";
    }
    if (!formData.addressLine1) {
      error.addressLine1 = "Address Line 1 is required";
    }
    if (!formData.addressLine2) {
      error.addressLine2 = "Address Line 2 is required";
    }
    if (!formData.city) {
      error.city = "City is required";
    }
    if (!formData.state) {
      error.state = "State is required";
    }
    if (!formData.postalCode) {
      error.postalCode = "Postal Code is required";
    } else if (!/^\d{4}$/.test(formData.postalCode)) {
      error.postalCode = "Postal Code must be exactly four digits";
    }
    if (!formData.country) {
      error.country = "Country is required";
    }

    setValidationErrors(error);
    return Object.keys(error).length === 0;
  };

  const handleAddAddress = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const addAddress = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        phone_number: formData.phone,
        address_line_1: formData.addressLine1,
        address_line_2: formData.addressLine2,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        postal_code: formData.postalCode,
        is_shipping: true,
        is_billing: false,
      };
      try {
        const response = await axios.post(
          `${API_URL.userAddress}`,
          addAddress,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        );
        setShowAddress(false);
        setFormData({
          firstName: "",
          lastName: "",
          phone: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          postalCode: "",
          country: "",
        });
        setAlertMsg(response.data?.message);
        setTimeout(() => {
          setAlertMsg("");
        }, 800);
        setTimeout(() => {
          window.location.reload();
        }, 1200);
      } catch (error) {
        console.error(error.response.data?.message);
      }
    }
  };
  const [addressId, setAddressId] = useState(0);
  const handleOpenPopup = (index) => {
    setEditAddress(true);
    setAddressId(index);
  };
  const handleClosePopup = () => {
    setEditAddress(false);
    window.location.reload();
  };
  const handleEditAddress = async (e, id, addressID) => {
    e.preventDefault();
    const editAddress = {
      first_name: formData.firstName || userAddress[id]?.first_name,
      last_name: formData.lastName || userAddress[id]?.last_name,
      phone_number: formData.phone || userAddress[id]?.phone_number,
      address_line_1: formData.addressLine1 || userAddress[id]?.address_line_1,
      address_line_2: formData.addressLine2 || userAddress[id]?.address_line_2,
      city: formData.city || userAddress[id]?.city,
      state: formData.state || userAddress[id]?.state,
      country: formData.country || userAddress[id]?.last_name,
      postal_code: formData.postalCode || userAddress[id]?.postal_code,
    };
    const error = {};
    if (!editAddress.phone_number) {
      error.phone = "Phone Number is required";
    } else if (editAddress.phone_number.length !== 10) {
      error.phone = "Invalid phone number format (10 digits)";
    } else {
      try {
        const response = await axios.put(
          `${baseURL}/Users/updateaddress/?address_id=${addressID}`,
          editAddress,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        );
        setAlertMsg(response.data?.message);
        setEditAddress(false);
        setTimeout(() => {
          setAlertMsg("");
        }, 800);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } catch (error) {
        console.error(error.response.data?.message);
      }
    }
    setValidationErrors(error);
  };
  const deleteUserAddress = async (id) => {
    try {
      const response = await axios.delete(
        `${baseURL}/Users/deleteaddress/?shipping_address_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      // Handle the response data
      setAlertMsg(response.data?.message);
      setTimeout(() => {
        setAlertMsg("");
      }, 800);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  };

  const makeDefault = async (id) => {
    const defaultData = {
      address_id: id,
      is_default: true,
    };
    try {
      const response = await axios.put(
        `${baseURL}/Users/shippingisdefault/`,
        defaultData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );
      console.log(response?.data);
      setTimeout(() => {
        window.location.reload(true);
      }, 300);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  return (
    <div className='address-label-container'>
      {alertMsg && (
        <div className='d-flex justify-content-center'>
          <div className='alert alert-success text-center '>{alertMsg}</div>
        </div>
      )}
      <div className='address-label-button-container'>
        <p className='address-label-title'>Shipping Address</p>
        <button
          className='address-label-button'
          onClick={() => setShowAddress(true)}
        >
          +Add New Address
        </button>
      </div>
      {userAddress &&
        userAddress.length > 0 &&
        userAddress.map((item, index) => {
          const {
            address_line_1,
            address_line_2,
            city,
            country,
            first_name,
            is_default,
            last_name,
            phone_number,
            postal_code,
            shipping_address_id,
            state,
            id,
          } = item;

          return (
            <div
              className='address-label'
              key={id}
            >
              <input
                type='radio'
                name='user-address'
                defaultChecked={is_default}
                onChange={(e) => makeDefault(id)}
              />
              <span className='address-label-title'>
                {first_name} {last_name}
              </span>
              <div className='mt-2 text-muted'>
                {first_name} {last_name},{phone_number},
                <br />
                {address_line_1}, {address_line_2}, {city}, {state}, {country},{" "}
                {postal_code}{" "}
              </div>
              <div className='address-button-container'>
                <button
                  type='button'
                  className='address-button px-lg-2 '
                  onClick={() => handleOpenPopup(index)}
                >
                  Edit
                </button>
                <button
                  type='button'
                  className='address-button px-lg-2'
                  onClick={() => deleteUserAddress(shipping_address_id)}
                >
                  Remove
                </button>
              </div>
            </div>
          );
        })}
      {showAddress && (
        <div className='AddAddress-popup'>
          <div className='cart-popup '>
            <div className='d-flex justify-content-between align-items-center'>
              <h5 className=''>Add new Address</h5>
              <button
                className='close-cart-popup'
                onClick={() => handleClosePopup()}
              >
                &#x2715;
              </button>
            </div>
            <form>
              <div className='row'>
                <div className='col-lg-6 mb-2 '>
                  <label htmlFor='firstName'>First Name</label>
                  <input
                    type='text'
                    className={`form-control ${validationErrors.firstName ? "is-invalid" : ""
                      }`}
                    id='firstName'
                    name='firstName'
                    placeholder='Enter your first Name'
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                  {validationErrors.firstName && (
                    <div className='invalid-feedback'>
                      {validationErrors.firstName}
                    </div>
                  )}
                </div>
                <div className='col-lg-6 mb-2'>
                  <label htmlFor='lastName'>Last Name</label>
                  <input
                    type='text'
                    className={`form-control ${validationErrors.lastName ? "is-invalid" : ""
                      }`}
                    id='lastName'
                    name='lastName'
                    placeholder='Enter your last Name'
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                  {validationErrors.lastName && (
                    <div className='invalid-feedback'>
                      {validationErrors.lastName}
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 mb-2 '>
                  <label htmlFor='phone'>Phone Number</label>
                  <input
                    type='tel'
                    className={`form-control ${validationErrors.phone ? "is-invalid" : ""
                      }`}
                    id='phone'
                    name='phone'
                    placeholder='Enter your phone Number'
                    max={10}
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  {validationErrors.phone && (
                    <div className='invalid-feedback'>
                      {validationErrors.phone}
                    </div>
                  )}
                </div>
              </div>
              <h5>Address</h5>
              <div className='row'>
                <div className='col-lg-6 mb-2'>
                  <label htmlFor='address_line_1'>Address 1</label>
                  <input
                    type='text'
                    className={`form-control ${validationErrors.addressLine1 ? "is-invalid" : ""
                      }`}
                    id='addressLine1'
                    name='addressLine1'
                    placeholder='Enter your address line 1'
                    value={formData.addressLine1}
                    onChange={handleChange}
                  />
                  {validationErrors.addressLine1 && (
                    <div className='invalid-feedback'>
                      {validationErrors.addressLine1}
                    </div>
                  )}
                </div>
                <div className='col-lg-6 mb-2'>
                  <label htmlFor='address_line_2'>Address 2</label>
                  <input
                    type='text'
                    className={`form-control ${validationErrors.addressLine2 ? "is-invalid" : ""
                      }`}
                    id='addressLine2'
                    name='addressLine2'
                    placeholder='Enter your address line 2'
                    value={formData.addressLine2}
                    onChange={handleChange}
                  />
                  {validationErrors.addressLine2 && (
                    <div className='invalid-feedback'>
                      {validationErrors.addressLine2}
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 mb-2'>
                  <label htmlFor='city'>City</label>
                  <input
                    type='text'
                    className={`form-control ${validationErrors.city ? "is-invalid" : ""
                      }`}
                    id='city'
                    name='city'
                    placeholder='Enter your city'
                    value={formData.city}
                    onChange={handleChange}
                  />
                  {validationErrors.city && (
                    <div className='invalid-feedback'>
                      {validationErrors.city}
                    </div>
                  )}
                </div>
                <div className='col-lg-6 mb-2'>
                  <label htmlFor='state'>State</label>
                  <input
                    type='text'
                    className={`form-control ${validationErrors.state ? "is-invalid" : ""
                      }`}
                    id='state'
                    name='state'
                    placeholder='Enter your state'
                    value={formData.state}
                    onChange={handleChange}
                  />
                  {validationErrors.state && (
                    <div className='invalid-feedback'>
                      {validationErrors.state}
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 mb-2'>
                  <label htmlFor='postalCode'>Postal Code</label>
                  <input
                    type='text'
                    className={`form-control ${validationErrors.postalCode ? "is-invalid" : ""
                      }`}
                    id='postalCode'
                    name='postalCode'
                    placeholder='Enter your postal Code'
                    value={formData.postalCode}
                    onChange={handleChange}
                  />
                  {validationErrors.postalCode && (
                    <div className='invalid-feedback'>
                      {validationErrors.postalCode}
                    </div>
                  )}
                </div>
                <div className='col-lg-6 mb-2'>
                  <label htmlFor='country'>Country</label>
                  <input
                    type='text'
                    className={`form-control ${validationErrors.country ? "is-invalid" : ""
                      }`}
                    id='country'
                    name='country'
                    placeholder='Enter your country'
                    value={formData.country}
                    onChange={handleChange}
                  />
                  {validationErrors.country && (
                    <div className='invalid-feedback'>
                      {validationErrors.country}
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-6 mb-3  mt-4'>
                  <button
                    className='btn-cancel'
                    type='button'
                    onClick={() => handleClosePopup()}
                  >
                    Cancel
                  </button>
                </div>
                <div className='col-6 mb-3 mt-4'>
                  <button
                    className='btn-save'
                    onClick={(e) => handleAddAddress(e)}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {editAddress && userAddress && (
        <div className='AddAddress-popup'>
          <div className='cart-popup '>
            <div className='d-flex justify-content-between align-items-center'>
              <h5 className=''>Add new Address</h5>
              <button
                className='close-cart-popup'
                onClick={() => handleClosePopup()}
              >
                &#x2715;
              </button>
            </div>
            <form>
              <div className='row'>
                <div className='col-lg-6 mb-2 '>
                  <label htmlFor='firstName'>First Name</label>
                  <input
                    type='text'
                    className={`form-control ${validationErrors.firstName ? "is-invalid" : ""
                      }`}
                    id='firstName'
                    name='firstName'
                    placeholder='Enter your first Name'
                    defaultValue={userAddress[addressId]?.first_name}
                    onChange={handleChange}
                  />
                  {validationErrors.firstName && (
                    <div className='invalid-feedback'>
                      {validationErrors.firstName}
                    </div>
                  )}
                </div>
                <div className='col-lg-6 mb-2'>
                  <label htmlFor='lastName'>Last Name</label>
                  <input
                    type='text'
                    className={`form-control ${validationErrors.lastName ? "is-invalid" : ""
                      }`}
                    id='lastName'
                    name='lastName'
                    placeholder='Enter your last Name'
                    defaultValue={userAddress[addressId]?.last_name}
                    onChange={handleChange}
                  />
                  {validationErrors.lastName && (
                    <div className='invalid-feedback'>
                      {validationErrors.lastName}
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 mb-2 '>
                  <label htmlFor='phone'>Phone Number</label>
                  <input
                    type='tel'
                    className={`form-control ${validationErrors.phone ? "is-invalid" : ""
                      }`}
                    id='phone'
                    name='phone'
                    placeholder='Enter your phone Number'
                    max={10}
                    defaultValue={userAddress[addressId]?.phone_number}
                    onChange={handleChange}
                  />
                  {validationErrors.phone && (
                    <div className='invalid-feedback'>
                      {validationErrors.phone}
                    </div>
                  )}
                </div>
              </div>
              <h5>Address</h5>
              <div className='row'>
                <div className='col-lg-6 mb-2'>
                  <label htmlFor='address_line_1'>Address 1</label>
                  <input
                    type='text'
                    className={`form-control ${validationErrors.addressLine1 ? "is-invalid" : ""
                      }`}
                    id='addressLine1'
                    name='addressLine1'
                    placeholder='Enter your address line 1'
                    defaultValue={userAddress[addressId]?.address_line_1}
                    onChange={handleChange}
                  />
                  {validationErrors.addressLine1 && (
                    <div className='invalid-feedback'>
                      {validationErrors.addressLine1}
                    </div>
                  )}
                </div>
                <div className='col-lg-6 mb-2'>
                  <label htmlFor='address_line_2'>Address 2</label>
                  <input
                    type='text'
                    className={`form-control ${validationErrors.addressLine2 ? "is-invalid" : ""
                      }`}
                    id='addressLine2'
                    name='addressLine2'
                    placeholder='Enter your address line 2'
                    defaultValue={userAddress[addressId]?.address_line_2}
                    onChange={handleChange}
                  />
                  {validationErrors.addressLine2 && (
                    <div className='invalid-feedback'>
                      {validationErrors.addressLine2}
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 mb-2'>
                  <label htmlFor='city'>City</label>
                  <input
                    type='text'
                    className={`form-control ${validationErrors.city ? "is-invalid" : ""
                      }`}
                    id='city'
                    name='city'
                    placeholder='Enter your city'
                    defaultValue={userAddress[addressId]?.city}
                    onChange={handleChange}
                  />
                  {validationErrors.city && (
                    <div className='invalid-feedback'>
                      {validationErrors.city}
                    </div>
                  )}
                </div>
                <div className='col-lg-6 mb-2'>
                  <label htmlFor='state'>State</label>
                  <input
                    type='text'
                    className={`form-control ${validationErrors.state ? "is-invalid" : ""
                      }`}
                    id='state'
                    name='state'
                    placeholder='Enter your state'
                    defaultValue={userAddress[addressId]?.state}
                    onChange={handleChange}
                  />
                  {validationErrors.state && (
                    <div className='invalid-feedback'>
                      {validationErrors.state}
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 mb-2'>
                  <label htmlFor='postalCode'>Postal Code</label>
                  <input
                    type='text'
                    className={`form-control ${validationErrors.postalCode ? "is-invalid" : ""
                      }`}
                    id='postalCode'
                    name='postalCode'
                    placeholder='Enter your postal Code'
                    defaultValue={userAddress[addressId]?.postal_code}
                    onChange={handleChange}
                  />
                  {validationErrors.postalCode && (
                    <div className='invalid-feedback'>
                      {validationErrors.postalCode}
                    </div>
                  )}
                </div>
                <div className='col-lg-6 mb-2'>
                  <label htmlFor='country'>Country</label>
                  <input
                    type='text'
                    className={`form-control ${validationErrors.country ? "is-invalid" : ""
                      }`}
                    id='country'
                    name='country'
                    placeholder='Enter your country'
                    defaultValue={userAddress[addressId]?.country}
                    onChange={handleChange}
                  />
                  {validationErrors.country && (
                    <div className='invalid-feedback'>
                      {validationErrors.country}
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-6 mb-3  mt-4'>
                  <button
                    className='btn-cancel'
                    type='button'
                    onClick={() => handleClosePopup()}
                  >
                    Cancel
                  </button>
                </div>
                <div className='col-6 mb-3 mt-4'>
                  <button
                    className='btn-save'
                    onClick={(e) => {
                      handleEditAddress(
                        e,
                        addressId,
                        userAddress[addressId]?.id,
                      );
                    }}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export { Addresses };
