import React from "react";
import { Link } from "react-router-dom";
import { Menu, MobileHeader, GlobalSearch, NavIcons } from "components";
import Logo from "assets/icons/BB-Logo.png";

const Header = ({ headerNav, cartItems }) => {
  return (
    <div>
      <div className='header-desktop-section'>
        <div className='header-section-container px-5'>
          <div className='header-container'>
            <div className='col'>
              <Link to='/' className="header-logo">
                <img
                  src={Logo}
                  alt=''
                />
              </Link>
            </div>
            <div className='col'>
              <GlobalSearch />
            </div>
            <div className='col'>
              <NavIcons cartItems={cartItems} />
            </div>
          </div>
        </div>
        <Menu headerNav={headerNav} />
      </div>
      <div className='header-mobile-section'>
        <MobileHeader
          headerNav={headerNav}
          cartItems={cartItems}
        />
      </div>
    </div>
  );
};

export { Header };
