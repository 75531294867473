import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "api/apiUrls";
import { Link, useNavigate } from "react-router-dom";
import { ProductCard, Seo } from "components";

const Wishlist = ({ token }) => {
  const navigate = useNavigate();
  const [wishList, setWishList] = useState([]);

  useEffect(() => {
    const getWishList = async () => {
      try {
        const response = await axios.get(`${API_URL.getWishList}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        setWishList(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (token) {
      getWishList();
    } else {
      navigate("/login");
    }
  }, [token, navigate]);

  return (
    <div className='container my-5'>
      <Seo
        metaTitle='My Wishlist'
        metaDescription='Get in touch today!, Need any help please contact us '
      />
      <div className=' row'>
        {wishList && wishList.length > 0 ? (
          <ProductCard data={wishList} />
        ) : (
          <div className='text-center my-5 fw-semibold'>
            <h2>No Items in Wishlist</h2>
            <Link
              to='/'
              className='btn btn-primary'
            >
              Go back
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Wishlist;
