import React, { useState, useMemo } from "react";
import checkIcon from "assets/icons/check-svgrepo-com.svg";
import { ProductImages } from "./ProductImages";
import axios from "axios";
import { API_URL, baseURL } from "api/apiUrls";
import { Pagination } from "components";

function SingleProductPage({ data }) {
  const [itemQuantity, SetItemQuantity] = useState(1);

  const incrementItemQuantity = () => SetItemQuantity(itemQuantity + 1);
  let decrementItemQuantity = () => SetItemQuantity(itemQuantity - 1);
  if (itemQuantity <= 1) {
    decrementItemQuantity = () => SetItemQuantity(1);
  }

  const [cartMsg, setCartMsg] = useState("");
  const [showCartMsg, setShowCartMsg] = useState(false);

  const token = sessionStorage.getItem("accessToken");
  const sessionID = sessionStorage.getItem("sessionID");
  const [showPopup, setShowPopup] = useState(false);

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleAddToCart = async (id, price) => {
    try {
      const addToCartData = {
        product: `${id}`,
        quantity: itemQuantity,
        unit_price: price,
        sub_total: itemQuantity * price,
      };

      if (token) {
        const response = await axios.post(API_URL.addToCart, addToCartData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        localStorage.setItem("cartID", response.data?.data.cart_id);
        setCartMsg(response.data?.message);
        setShowCartMsg(true);
      }
      if (sessionID) {
        const response = await axios.post(
          `${API_URL.addToCart}?session_id=${sessionID}`,
          addToCartData,
        );
        localStorage.setItem("cartID", response.data?.data.cart_id);
        setCartMsg(response.data?.message);
        setShowCartMsg(true);
      }
    } catch (error) {
      setCartMsg(error.response.data.message);
      setShowCartMsg(true);
    }
  };
  const strikedPrice = Math.trunc(
    Number(data?.price) -
    Number(data?.price) * (Number(data?.offer_percentage) / 100),
  );
  const handleClose = () => {
    window.location.reload(true);
    setShowCartMsg(false);
  };
  const [selectedRating, setSelectedRating] = useState(0);
  const [addReview, setAddReview] = useState("");

  const handleReview = async () => {
    const loggedInEmail = sessionStorage.getItem("loggedInEmail");
    const reviewData = {
      email: loggedInEmail,
      review: addReview,
      rating: selectedRating,
    };
    try {
      const response = await axios.post(
        `${baseURL}/Users/ratingapi/?product_id=${data?.id}`,
        reviewData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );
      setCartMsg(response.data?.message);
      setShowCartMsg(true);
      handleClosePopup();
    } catch (error) {
      setCartMsg(error.response.data?.message);
      setShowCartMsg(true);
    }
  };

  const handleWishList = async (id) => {
    const token = sessionStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${API_URL.addWishList}?product_id=${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );
      setCartMsg(response.data?.message);
      setShowCartMsg(true);
    } catch (error) {
      setCartMsg(error.response.data?.message);
      setShowCartMsg(true);
    }
  };

  const [gstType, setGstType] = useState("");

  const pageSize = 3;
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data?.product_reviews?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data?.product_reviews]);

  return (
    <>
      <div className='Product-Details-wrapper overflow-x-hidden'>
        <div className='row'>
          <div className='col-lg-7'>
            <div className='d-flex justify-content-center align-items-center h-100'>
              <ProductImages images={data?.images} />
            </div>
          </div>
          <div className='col-lg-5'>
            <div className='product-details'>
              <h1 className='fw-semibold my-2 my-md-4'>{data?.name}</h1>
              <p className='brand-name'>Brand : {data?.brand}</p>
              <div className='d-flex align-items-center'>
                {gstType === "inc" ? (
                  <h3 className='fw-semibold selling-price '>
                    ${Number(strikedPrice + strikedPrice * 0.15)}{" "}
                    <span className='selling-price-gst'>( Inc GST )</span>
                  </h3>
                ) : (
                  <h3 className='fw-semibold selling-price '>
                    ${strikedPrice}{" "}
                    <span className='selling-price-gst'>( Exc GST )</span>
                  </h3>
                )}
                <div className='ms-3'>
                  <select
                    className='mt-0'
                    value={gstType}
                    onChange={(e) => setGstType(e.target.value)}
                  >
                    <option value=''> -GST Type-</option>
                    <option value='exc'>GST Excluded</option>
                    <option value='inc'>GST Included</option>
                  </select>
                </div>
              </div>
              {data?.offer_percentage !== "0" && (
                <strike>{data?.price}</strike>
              )}
              <h4 className='my-0 my-md-3'>
                {data?.offer_percentage !== "0" && (
                  <div
                    className='badge text-white py-2 px-3'
                    style={{ background: "#F47E11" }}
                  >
                    {data?.offer_percentage}% Off
                  </div>
                )}
              </h4>
              <h4 className='availability my-md-3 my-2 '>
                Availability :
                {data?.quantity >= 1 ? (
                  <span className='text-success'> In Stock </span>
                ) : (
                  <span className='text-danger'> Out of Stock </span>
                )}
              </h4>
              <div className='d-flex justify-content-start align-items-center my-md-4 my-1'>
                <h5> Quantity :</h5>
                <div className='d-flex justify-content-start align-items-center input-group  mx-3'>
                  <button
                    className='input-group-text'
                    onClick={decrementItemQuantity}
                  >
                    -
                  </button>
                  <div className='form-control d-flex justify-content-center'>
                    {itemQuantity}
                  </div>
                  <button
                    className='input-group-text'
                    onClick={incrementItemQuantity}
                  >
                    +
                  </button>
                </div>
              </div>
              <div>
                <p className='brand-name'>
                  <div>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      fill='green'
                      className='bi bi-check-circle-fill me-2'
                      viewBox='0 0 16 16'
                    >
                      <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z' />
                    </svg>
                    Warranty:{" "}
                    {data?.warranty_year === 0 && data?.warranty_month === 0 ? (
                      <span> Not Applicable</span>
                    ) : (
                      <span>
                        {" "}
                        {!data?.warranty_year === 0 && data?.warranty_year}{" "}
                        {data?.warranty_year === 1 && `${data?.warranty_year} year`}
                        {data?.warranty_year > 1 && `${data?.warranty_year} years`}
                        {!data?.warranty_month === 0 && data?.warranty_month}{" "}
                        {data?.warranty_month === 1 && `${data?.warranty_month} month`}
                        {data?.warranty_month > 1 && `${data?.warranty_month} months`}
                      </span>
                    )}
                  </div>

                </p>
              </div>
              <div>
                <button
                  className=' btn addTo-Cart-btn my-md-3 my-2 '
                  onClick={() => handleAddToCart(data?.id, strikedPrice)}
                >
                  Add to Cart
                </button>
              </div>
              <div>
                <button
                  className=' btn addTo-wishlist-btn mb-md-3 mb-2'
                  onClick={() => handleWishList(data?.id)}
                >
                  {data?.is_wishlist
                    ? "Remove from Wishlist"
                    : "Add to Wishlist"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='ProductDescription'>
          <h4 className='mb-0 ProductDescription-head d-inline'>Description</h4>
          <hr className='mt-0' />

          <p className='ProductDescription-para'>{data?.description}</p>

          <div className='row '>
            <div className='col-lg-12 table-responsive'>
              <table className='table-bordered'>
                <tbody>
                  {data?.specifications &&
                    data?.specifications.length > 0 &&
                    data?.specifications.map((item, index) => {
                      const { specKey, specValue } = item;
                      return (
                        <tr key={index + 1}>
                          <td className='specifications-heading'>{specKey}</td>
                          <td> {specValue} </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col-lg-12 col-12'>
              <div className='points'>
                <ul className='list-unstyled'>
                  {data?.features &&
                    data?.features.length > 0 &&
                    data?.features.map((item, index) => {
                      const { feature } = item;
                      return (
                        <li
                          key={index + 1}
                          className='d-flex text-wrap justify-content-start align-items-center mb-md-3 mb-1'
                        >
                          <div className='me-md-3 me-1'>
                            <img
                              src={checkIcon}
                              alt='CheckIcon'
                            />
                          </div>{" "}
                          {feature}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-lg-4 rating-section'>
              <div>
                <div className='d-flex align-items-center mb-2'>
                  <h2 className='rating-number fw-bold me-2'>
                    {data?.pcdt_rating || "No Reviews"}
                  </h2>
                  <div className='star-rating'>
                    <span className='star-icon '></span>
                  </div>
                </div>
                <p className=''> {data?.reviewers_count | 0} verified Buyers</p>
              </div>
              <div className='rating-progress d-flex'>
                <p>5 </p>
                <div className='star-rating'>
                  <span className='star-icon filled'></span>
                </div>
                <div className='progress'>
                  <div
                    className='progress-bar bg-success'
                    style={{ width: "100%" }}
                  ></div>
                </div>
                <p className='text-muted'>{data?.fivestar_rating}</p>
              </div>

              <div className='rating-progress d-flex'>
                <p>4 </p>
                <div className='star-rating'>
                  <span className='star-icon filled'></span>
                </div>
                <div className='progress'>
                  <div
                    className='progress-bar bg-success'
                    style={{ width: "80%" }}
                  ></div>
                </div>
                <p className='text-muted'>{data?.fourstar_rating}</p>
              </div>
              <div className='rating-progress d-flex'>
                <p>3 </p>
                <div className='star-rating'>
                  <span className='star-icon filled'></span>
                </div>
                <div className='progress'>
                  <div
                    className='progress-bar bg-warning'
                    style={{ width: "60%" }}
                  ></div>
                </div>
                <p className='text-muted'>{data?.threestar_rating}</p>
              </div>
              <div className='rating-progress d-flex'>
                <p>2 </p>
                <div className='star-rating'>
                  <span className='star-icon filled'></span>
                </div>
                <div className='progress'>
                  <div
                    className='progress-bar bg-warning'
                    style={{ width: "40%" }}
                  ></div>
                </div>
                <p className='text-muted'>{data?.twostar_rating}</p>
              </div>
              <div className='rating-progress d-flex'>
                <p>1 </p>
                <div className='star-rating'>
                  <span className='star-icon filled'></span>
                </div>
                <div className='progress'>
                  <div
                    className='progress-bar bg-danger'
                    style={{ width: "20%" }}
                  ></div>
                </div>
                <p className='text-muted'>{data?.onestar_rating}</p>
              </div>
              <button
                type='button'
                className='btn-reviewandrating'
                onClick={handleShowPopup}
              >
                + Add Review And Rating
              </button>
            </div>
            {showPopup && (
              <div className='popup active'>
                <div className='popup-content'>
                  <div className='popup-header'>
                    <h2 className='popuptext'>Review And Rate this Product</h2>
                    <h3 className='ratingtext'>Rate this product</h3>
                    <div className='popupstar'>
                      {[1, 2, 3, 4, 5].map((star) => (
                        <button
                          key={star}
                          className='border-0 bg-transparent'
                          onClick={() => setSelectedRating(star)}
                        >
                          {star <= selectedRating ? (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='#FFD700' // Use the fill color for filled stars
                            >
                              <path d='M12 2l2.4 7.4H21l-6.2 4.6 2.4 7.4-6.2-4.6-6.2 4.6 2.4-7.4L3 9.4h6.6z' />
                            </svg>
                          ) : (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='gray' // Use no fill for empty stars
                            >
                              <path
                                d='M0 0h24v24H0z'
                                fill='none'
                              />
                              <path d='M12 2l2.4 7.4H21l-6.2 4.6 2.4 7.4-6.2-4.6-6.2 4.6 2.4-7.4L3 9.4h6.6z' />
                            </svg>
                          )}
                        </button>
                      ))}
                      {/* <button
                        className='border-0 bg-transparent'
                        onClick={() => setAddRating(1)}
                      >
                        &#11088;
                      </button>
                      <button
                        className='border-0 bg-transparent'
                        onClick={() => setAddRating(2)}
                      >
                        &#11088;
                      </button>
                      <button
                        className='border-0 bg-transparent'
                        onClick={() => setAddRating(3)}
                      >
                        &#11088;
                      </button>
                      <button
                        className='border-0 bg-transparent'
                        onClick={() => setAddRating(4)}
                      >
                        &#11088;
                      </button>
                      <button
                        className='border-0 bg-transparent'
                        onClick={() => setAddRating(5)}
                      >
                        &#11088;
                      </button> */}
                    </div>
                  </div>
                  <div className='popup-body'>
                    <label>Review</label>
                    <textarea
                      className='form-control'
                      rows='6'
                      placeholder='Add your comments here'
                      id='floatingTextarea'
                      onChange={(e) => setAddReview(e.target.value)}
                    ></textarea>
                  </div>
                  <div className='popup-footer'>
                    <button
                      className='popup-button cancel'
                      onClick={handleClosePopup}
                    >
                      Cancel
                    </button>
                    <button
                      className='popup-button submit'
                      onClick={() => {
                        handleReview();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className='col-lg-8 '>
              {currentTableData &&
                currentTableData.length > 0 &&
                currentTableData.map((item) => {
                  const { id, date_time, user_name, rating, review } = item;
                  return (
                    <div
                      className='customer-review mb-3'
                      key={id}
                    >
                      <div className='d-flex align-items-center mb-3'>
                        <div className='d-flex review-s'>
                          <div className='rating-number fw-bold me-2'>
                            {rating}
                          </div>
                          <div className='star-rating'>
                            <span className='star-icon filled'></span>
                          </div>
                        </div>
                        <div className='customer-name ms-2'>{user_name} |</div>
                        <div className='review-date ms-2'>{date_time}</div>
                      </div>
                      <p className='customer-review-text'>{review}</p>
                    </div>
                  );
                })}
            </div>
            {data?.product_reviews && data?.product_reviews.length > 0 && (
              <Pagination
                className='pagination-bar'
                currentPage={currentPage}
                totalCount={data?.product_reviews.length}
                pageSize={pageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
          </div>
        </div>
      </div>
      {showCartMsg && (
        <div className='cart-popup text-center'>
          <div className='cart-message'>{cartMsg}</div>
          <button
            className='close-cart-popup'
            onClick={() => handleClose()}
          >
            Close
          </button>
        </div>
      )}
    </>
  );
}

export { SingleProductPage };
