import { React } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { API_URL } from "api/apiUrls";
import { useNavigate } from "react-router-dom";
import GoogleSvg from "assets/icons/google.svg";

const GoogleLoginBtn = () => {
  const navigate = useNavigate();
  const OnSuccessResponseGoogle = async (credentialResponse) => {
    const DecryptedCredentials = jwtDecode(credentialResponse.credential);
    console.log(DecryptedCredentials);
    console.log(credentialResponse);
    const googleData = {
      login_type: "google",
      email: DecryptedCredentials.email,
      social_id: credentialResponse.clientId,
      first_name: DecryptedCredentials.given_name,
      last_name: DecryptedCredentials.family_name,
    };
    console.log(googleData, "$$$");
    try {
      const response = await axios.post(API_URL.googleLogin, googleData);
      console.log(response, "Response---------------------");

      sessionStorage.setItem("accessToken", response.data?.data?.access);
      sessionStorage.setItem("loggedInUser", response.data?.data.user_name);
      sessionStorage.setItem("loggedInEmail", response.data?.data.user_email);
      if (
        response.data?.message === "Login Success with Google" &&
        window.location.pathname === "/guest-login"
      ) {
        navigate("/registered-address");
      } else if (response.data?.message === "Login Success with Google") {
        navigate("/");
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="Social-btn-container-inner-icons">
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID}>
        <GoogleLogin
          width="1000"
          logo_alignment="center"
          shape="circle"
          type="icon"
          cancel_on_tap_outside={true}
          text="continue_with"
          theme="outline"
          onSuccess={OnSuccessResponseGoogle}
          onError={() => {
            console.log("Login Failed");
          }}
          useOneTap="false"
        />
      </GoogleOAuthProvider>
    </div>
  );
};
export { GoogleLoginBtn };
