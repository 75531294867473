import React from 'react'
import { Link } from 'react-router-dom';

function HomeGridSection({data}) {
  return (
    <>
    <div className="home-grid my-md-5 my-2">
        <div className="row">
            <div className="col-md-6 home-grid-left home-grid-poster">
                <Link to={data[0].url}>
                <img className="img-fluid h-100"  src={data[0].image} alt="" />
                </Link>
            </div>
            <div className="col-md-6 home-grid-right">
                <div className='home-grid-right-sub'>
                <div className='home-grid-poster mb-3'>
               <Link to={data[1].url}>
               <img  className="img-fluid top" src={data[1].image} alt="" />
               </Link>
                </div>
                <div className="row mb-0">
                    <div className="col-6 home-grid-poster ">
                    <Link to={data[2].url}>
                    <img className="img-fluid mt-2 h-100" src={data[2].image} alt="" />
                    </Link>
                    </div>
                    <div className="col-6 home-grid-poster ">
                    <Link to={data[3].url}><img className="img-fluid mt-2 h-100" src={data[3].image} alt="" /></Link>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export  {HomeGridSection};