import React from "react";
import { ReactComponent as RightArrow } from "assets/icons/rightarrow.svg";

const CheckOutSteps = () => {
  return (
    <div className="CheckOutSteps">
      <div className="guest-registered-section">
        <div className="guest-registered-section-inner">
          <span>1</span> Address
          <div>
            <RightArrow />
          </div>
        </div>
        <div className="guest-registered-section-inner">
          <span>2</span> Payment
          <div>
            <RightArrow />
          </div>
        </div>
        <div className="guest-registered-section-inner">
          <span>3</span> <div className="ms-1">Confirmation</div>
        </div>
      </div>
    </div>
  );
};

export { CheckOutSteps };
