import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "api/apiUrls";

const PersonalInformation = ({ token }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });

  const validateName = (name) => {
    const nameRegex = /^[A-Za-z]+$/;
    return nameRegex.test(name);
  };
  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^\d{10,}$/;
    return phoneNumberRegex.test(phoneNumber);
  };

  const [cartMsg, setCartMsg] = useState("");
  const [showCartMsg, setShowCartMsg] = useState(false);

  //info update
  const updateInfo = async (firstName, lastName, phoneNumber) => {
    const updatedInfo = {
      first_name: firstName,
      last_name: lastName,
      phone_number: Number(phoneNumber),
    };
    try {
      const response = await axios.put(API_URL.userUpdateInfo, updatedInfo, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setCartMsg(response.data?.message);
      setShowCartMsg(true);
    } catch (error) {
      setCartMsg(error.response.data.message);
      setShowCartMsg(true);
    }
  };
  
  const handleClose = () => {
    window.location.reload(true);
    setShowCartMsg(false);
  };

  const handleSubmit = () => {
    const newErrors = {
      firstName: "",
      lastName: "",
      phoneNumber: "",
    };

    if (firstName === "") {
      newErrors.firstName = "First Name is required";
    } else if (!validateName(firstName)) {
      newErrors.firstName = "First Name should contain only letters";
    }

    if (lastName === "") {
      newErrors.lastName = "Last Name is required";
    } else if (!validateName(lastName)) {
      newErrors.lastName = "Last Name should contain only letters";
    }

    if (phoneNumber === "") {
      newErrors.phoneNumber = "Phone Number is required";
    } else if (!validatePhoneNumber(phoneNumber)) {
      newErrors.phoneNumber =
        "Phone Number should contain only numbers and should contains 10 digits";
    }

    setErrors(newErrors);

    if (!newErrors.firstName && !newErrors.lastName && !newErrors.phoneNumber) {
      updateInfo(firstName, lastName, phoneNumber);
    }
  };
  const [info, setInfo] = useState([]);

  useEffect(() => {
    const getInfo = async () => {
      try {
        const response = await axios.get(API_URL.usergetInfo, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        setInfo(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getInfo();
  }, [token]);

  useEffect(() => {
    setFirstName(info.first_name);
    setLastName(info.last_name);
    setPhoneNumber(info?.phone_number);
  }, [info]);

  return (
    <div className='personal-information'>
      <div className='row'>
        <div className='col-lg-7'>
          <div className='d-flex'>
            <div className='col-6 mb-3 me-2 mt-4'>
              <label htmlFor='firstName'>First Name</label>
              <input
                type='text'
                className='form-control'
                id='firstName'
                placeholder='First Name'
                defaultValue={info?.first_name}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <div className='error'>{errors.firstName}</div>
            </div>
            <div className='col-6 mb-3 mt-4'>
              <label htmlFor='lastName'>Last Name</label>
              <input
                type='text'
                className='form-control'
                id='lastName'
                placeholder='Last Name'
                defaultValue={info?.last_name}
                onChange={(e) => setLastName(e.target.value)}
              />
              <div className='error'>{errors.lastName}</div>
            </div>
          </div>
          <div className='col-12 mb-3 me-4'>
            <label htmlFor='email'>Email Id</label>
            <input
              type='email'
              className='form-control'
              id='email'
              placeholder='dummy@gmail.com'
              value={info?.email}
            />
          </div>
          <div className='col-12 mb-3 me-4'>
            <label htmlFor='phoneNumber'>Phone Number</label>
            <input
              type='text'
              className='form-control'
              id='phoneNumber'
              placeholder='+1(555) 000-000'
              defaultValue={info?.phone_number}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <div className='error'>{errors.phoneNumber}</div>
          </div>
          <div className='d-flex'>
            <div className='col-6 mb-3 me-2 mt-4'>
              <button className='btn-cancel'>Cancel</button>
            </div>
            <div className='col-6 mb-3 mt-4'>
              <button
                className='btn-save'
                onClick={handleSubmit}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCartMsg && (
        <div className='cart-popup text-center'>
          <div className='cart-message'>{cartMsg}</div>
          <button
            className='close-cart-popup'
            onClick={() => handleClose()}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export { PersonalInformation };
