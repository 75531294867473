import React, { useState, useEffect } from "react";
import { baseURL } from "api/apiUrls";
import axios from "axios";
import { useParams, Outlet, useNavigate } from "react-router-dom";
import { ProductCard, Seo, Sidebar, SortingDropdown } from "components";

const SubCategoryDetails = ({ headerNav, products, setProducts, token }) => {
  const params = useParams();
  const { category, subcategory } = params;
  const [error, setError] = useState("");
  const [sorted, setSorted] = useState("newest");
  const [maxPrice, setMaxPrice] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [checked, setChecked] = useState([]);
  const [filter, setFilter] = useState(false);
  // array to string
  var checkedItems = checked.length
    ? checked.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  useEffect(() => {
    const getProducts = async (id) => {
      try {
        if (token) {
          const response = await axios.get(
            `${baseURL}/Users/search/?page=1&item=100&search=${id}&filter=${filter}&brand=${checkedItems}&max_price=${maxPrice}&min_price=${minPrice}&sort=${sorted}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            },
          );

          // Handle the response data
          setProducts(response.data.data);
          setError("");
        } else {
          const response = await axios.get(
            `${baseURL}/Users/search/?page=1&item=100&search=${id}&filter=${filter}&brand=${checkedItems}&max_price=${maxPrice}&min_price=${minPrice}&sort=${sorted}`,
          );

          // Handle the response data
          setProducts(response.data.data);
          setError("");
        }
      } catch (error) {
        // Handle the error
        setError(error.response.data?.message);
        setProducts([]);
      }
    };

    getProducts(subcategory.split("-").join(" "));
  }, [
    subcategory,
    setProducts,
    sorted,
    maxPrice,
    minPrice,
    checkedItems,
    checked,
    filter,
    token,
  ]);

  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const getBrands = async (id) => {
      try {
        const response = await axios.get(
          `${baseURL}/Users/brandlist/?search=${id}`,
        );

        // Handle the response data
        setBrands(response?.data?.data);
      } catch (error) {
        // Handle the error
        setError(error?.response?.data?.message);
      }
    };
    getBrands(subcategory.split("-").join(" "));
  }, [subcategory]);

  const handleClearAll = () => {
    setFilter(false);
    setMaxPrice("");
    setMinPrice("");
    setChecked([]);
  };
  const sortOptions = [
    { value: "newest", view: "Newest" },
    { value: "oldest", view: "Oldest" },
    { value: "price_low_to_high", view: "Low to High" },
    { value: "price_high_to_low", view: "High to Low" },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    const filterSub = headerNav.category_subCategory?.filter((item) => {
      return (
        item.name.toLowerCase() === category.split("-").join(" ").toLowerCase()
      );
    });

    const filterPage =
      filterSub &&
      filterSub[0]?.subcategory?.filter((item) => {
        return (
          item.name.split(" ").join("-").toLowerCase() ===
          subcategory.toLowerCase()
        );
      });

    if (filterPage?.length <= 0) {
      navigate("/page-not-found");
    }
  }, [subcategory, headerNav, navigate, category]);

  const [gstType, setGstType] = useState("");
  
  return (
    <div className='sub-category-details overflow-x-hidden'>
      <Seo metaTitle={subcategory} metaDescription={"Sub category description"}/>
      <div className=''>
        <div className='row '>
          <div className='col-lg-3 '>
            <Sidebar
              data={headerNav?.category_subCategory}
              setMaxPrice={setMaxPrice}
              setMinPrice={setMinPrice}
              minPrice={minPrice}
              maxPrice={maxPrice}
              brands={brands}
              setChecked={setChecked}
              checked={checked}
              setFilter={setFilter}
            />
          </div>
          <div className='col-lg-9'>
            <div className='row'>
              <SortingDropdown
                setSorted={setSorted}
                products={products}
                sortOptions={sortOptions}
                title='Items in Tablets'
                setGstType={setGstType}
              />
              {filter && (
                <div className=' d-flex mb-4'>
                  {checked && checked.length > 0 && (
                    <div className='filter-styling'>
                      {checkedItems}{" "}
                      <button
                        onClick={() => setChecked([])}
                        className='fw-bold'
                      >
                        &#x2715;
                      </button>
                    </div>
                  )}
                  {minPrice !== "" && maxPrice !== "" && (
                    <div className='filter-styling'>
                      Max Price : {maxPrice}{" "}
                      <button
                        onClick={() => setMaxPrice("")}
                        className='fw-bold'
                      >
                        &#x2715;
                      </button>
                    </div>
                  )}
                  {minPrice !== "" && (
                    <div className='filter-styling'>
                      Min Price: {minPrice}{" "}
                      <button
                        onClick={() => setMinPrice("")}
                        className='fw-bold'
                      >
                        &#x2715;
                      </button>
                    </div>
                  )}
                  <button
                    className='text-primary'
                    onClick={() => handleClearAll()}
                  >
                    Clear All Filters
                  </button>
                </div>
              )}
              <div className='row px-2 mx-0'>
                {products.length > 0 && (
                  <ProductCard
                    data={products}
                    gstType={gstType}
                  />
                )}
              </div>
              {error && <div className='text-center h3 my-5 py-5'>{error}</div>}
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <Outlet />
    </div>
  );
};

export default SubCategoryDetails;
