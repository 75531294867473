import React, { useState, useEffect } from "react";
import { Seo, SingleProductPage } from "components";
import { useParams, Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "api/apiUrls";
import { Link } from "react-router-dom";
import { truncate } from "utils/index";
import { API_URL } from "api/apiUrls";
import {
  getSessionIdFromCookie,
  generateSessionId,
  setSessionIdInCookie,
} from "utils/index";
import redHeart from "assets/icons/red-heart.svg";
import grayHeart from "assets/icons/gray-heart.svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules

const SingleProductDetails = ({ token }) => {
  const params = useParams();
  const { productId } = params;
  const [singleProduct, setSingleProduct] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const getSingleProducts = async (id) => {
      try {
        if (token) {
          const response = await axios.get(
            `${baseURL}/Users/getproduct/?product_id=${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          setSingleProduct(response.data.data);
        } else {
          const response = await axios.get(
            `${baseURL}/Users/getproduct/?product_id=${id}`
          );
          setSingleProduct(response.data.data);
        }

        // Handle the response data
      } catch (error) {
        // Handle the error
        console.error(error.response.data?.message);
        navigate("/page-not-found");
      }
    };

    getSingleProducts(productId);
  }, [productId, navigate, token]);

  const { product, similar_product } = singleProduct;

  const [cartMsg, setCartMsg] = useState("");
  const [showCartMsg, setShowCartMsg] = useState(false);

  useEffect(() => {
    if (!token) {
      const sessionId = getSessionIdFromCookie();

      if (!sessionId) {
        const newSessionId = generateSessionId();
        setSessionIdInCookie(newSessionId);
      } else {
        sessionStorage.setItem("sessionID", sessionId);
      }
    }
  }, [token]);

  const sessionID = sessionStorage.getItem("sessionID");
  const handleAddToCart = async (id, price) => {
    try {
      const addToCartData = {
        product: `${id}`,
        quantity: 1,
        unit_price: price,
        sub_total: 1 * price,
      };
      if (sessionID) {
        const response = await axios.post(
          `${API_URL.addToCart}?session_id=${sessionID}`,
          addToCartData
        );
        localStorage.setItem("cartID", response.data?.data.cart_id);
        setCartMsg(response.data?.message);
        setShowCartMsg(true);
      }

      if (token) {
        const response = await axios.post(API_URL.addToCart, addToCartData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        localStorage.setItem("cartID", response.data?.data.cart_id);
        setCartMsg(response.data?.message);
        setShowCartMsg(true);
      }
    } catch (error) {
      setCartMsg(error.response.data.message);
      setShowCartMsg(true);
    }
  };

  const handleClose = () => {
    window.location.reload(true);
    setShowCartMsg(false);
  };

  const handleWishList = async (id) => {
    const token = sessionStorage.getItem("accessToken");
    if (!token) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        `${API_URL.addWishList}?product_id=${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setCartMsg(response.data?.message);
      setShowCartMsg(true);
    } catch (error) {
      setCartMsg(error.response.data?.message);
      setShowCartMsg(true);
    }
  };

  return (
    <div className="container">
      <Seo
        metaTitle={product?.name || "Product Name"}
        metaDescription="Single product page"
      />
      <SingleProductPage data={product} />
      {similar_product && similar_product.length > 0 && (
        <div>
          <div className="section-head my-3">
            <h2 className="fw-semibold">Similar Products</h2>
          </div>
          <Swiper
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              440: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1440: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            autoplay
            modules={{ Autoplay }}
            className="mySwiper "
            loop="true"
          >
            <div className="row">
              {similar_product &&
                similar_product.length >= 0 &&
                similar_product.map((item) => {
                  const {
                    id,
                    image,
                    name,
                    offer_percentage,
                    price,
                    sub_category_name,
                    category_name,
                    is_wishlist,
                    product_id,
                  } = item;
                  const link = `${category_name}/${sub_category_name}`;
                  const strikedPrice =
                    Number(price) -
                    Number(price) * (Number(offer_percentage) / 100);
                  return (
                    <SwiperSlide
                      className="col-lg-3 col-md-4 col-6 mb-5 me-2"
                      key={id}
                    >
                      <div className="Product-Card mx-lg-2">
                        <div className=" text-decoration-none">
                          <div className="p-md-3 p-2">
                            <div className="d-flex justify-content-between">
                              <div>
                                {offer_percentage !== "0" && (
                                  <div
                                    className="badge text-white py-2 px-3"
                                    style={{ background: "#F47E11" }}
                                  >
                                    ${offer_percentage}% Off
                                  </div>
                                )}
                                &nbsp;
                              </div>
                              <div
                                className="wishlist d-flex justify-content-center align-items-center"
                                onClick={() => handleWishList(product_id)}
                              >
                                {is_wishlist ? (
                                  <img src={redHeart} alt="gray-heart" />
                                ) : (
                                  <img src={grayHeart} alt="red-heart" />
                                )}
                              </div>
                            </div>
                            <Link
                              to={`/${link
                                .toLowerCase()
                                .split(" ")
                                .join("-")}/${product_id}`}
                              onClick={() => {
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                            >
                              <div className="Product-Card-image p-md-4 p-2  d-flex justify-content-center align-items-center">
                                <img src={image} className="img-fluid" alt="" />
                              </div>
                            </Link>
                            <p className="Product-Card-subhead text-body-secondary mb-1">
                              {sub_category_name === ""
                                ? category_name
                                : sub_category_name}
                            </p>
                            <p className="Product-Card-link text-truncate">
                              {" "}
                              {truncate(name, 25)}
                            </p>
                            <div className="pt-2">
                              <strong className="px-md-2 Product-Card-price d-block d-md-inline text-black">
                                ${Math.trunc(strikedPrice)}
                              </strong>
                              {offer_percentage !== "0" && (
                                <strike className="text-body-secondary">
                                  ${price}
                                </strike>
                              )}
                            </div>
                          </div>
                        </div>
                        <button
                          className="add-to-cart-btn text-center w-100 border-0 rounded-0 text-white py-2"
                          onClick={() => handleAddToCart(id, strikedPrice)}
                        >
                          Add to Cart
                        </button>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </div>
          </Swiper>
          {showCartMsg && (
            <div className="cart-popup text-center">
              <div className="cart-message">{cartMsg}</div>
              <button
                className="close-cart-popup"
                onClick={() => handleClose()}
              >
                Close
              </button>
            </div>
          )}
        </div>
      )}
      <Outlet />
    </div>
  );
};

export default SingleProductDetails;
