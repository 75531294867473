export const truncate = (str, max) => {
  const suffix = "...";
  return str.length < max
    ? str
    : `${str.substr(
        0,
        str.substr(0, max - suffix.length).lastIndexOf(" "),
      )}${suffix}`;
};

export function getSessionIdFromCookie() {
  const cookieValue = document.cookie.replace(
    /(?:(?:^|.*;\s*)sessionId\s*=\s*([^;]*).*$)|^.*$/,
    "$1",
  );
  return cookieValue;
}

export function generateSessionId() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

export function setSessionIdInCookie(sessionId) {
  document.cookie = `sessionId=${sessionId}; path=/`;
}

export const removeDuplicates = (array, key) => {
  return array.filter((item, index, self) => {
    return index === self.findIndex((t) => t[key] === item[key]);
  });
};
