import React from "react";
import { Link } from "react-router-dom";
import backbtn from "assets/icons/ArrowRightCircle.svg";
import { useParams, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { baseURL } from "api/apiUrls";
import { OrderTracking } from "components";
import { truncate } from "utils";

function MyOrdersViewPage({ token }) {
  const params = useParams();
  const { orderId } = params;
  const [singleOrder, setSingleOrder] = useState([]);

  const orderItemID = sessionStorage.getItem("ordersProduct");

  useEffect(() => {
    const getSingleOrder = async (id) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.get(
          `${baseURL}/Users/usersingleorder/?order_id=${id}&order_item_id=${orderItemID}`,
          config,
        );

        // Handle the response data
        setSingleOrder(response.data?.data);
      } catch (error) {
        // Handle the error
        console.error(error);
      }
    };
    getSingleOrder(orderId);
  }, [orderId, orderItemID, token]);

  const handleDownloadInvoice = async (id) => {
    const apiUrl = `${baseURL}/Users/downloadinvoice/?order_id=${id}`;
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob", // This is important to receive binary data
      });

      // Create a Blob object from the response data
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      // Create a downloadable link for the Blob
      const url = window.URL.createObjectURL(pdfBlob);

      // Create a link element and simulate a click to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "downloaded.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove(); // Clean up
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const {
    product_details,
    other_product_details,
    billing_address,
    order_details,
    shipping_address,
    subtotal,
    shipping,
    total_amount,
    gst,
  } = singleOrder;

  return (
    <>
      <div className='main_container container MyOrder-View'>
        <div className='header d-flex justify-content-between align-items-center  mb-5'>
          <div className='d-flex align-items-center'>
            <Link
              to={"/my-orders"}
              className='text-decoration-none'
            >
              <div>
                <img
                  className='m-2'
                  src={backbtn}
                  alt=''
                />
              </div>
            </Link>
            <p className='mb-0'>
              {" "}
              <span className='text-black fw-semibold'>Order:</span>{" "}
              <span>{order_details?.invoice_no}</span>
            </p>
          </div>
          <div>
            <button
              onClick={() => handleDownloadInvoice(orderId)}
              className='btn'
            >
              Download Invoice
            </button>
          </div>
        </div>
        <div>
          <div className='container padding-bottom-3x mb-1 shipping-tracker '>
            <div className=' mb-3'>
              <div className=''>
                <OrderTracking />
              </div>
            </div>
          </div>
          <div className='row d-flex justify-content-lg-between'>
            <div className='col-lg-5 col-12 mt-4 pt-2 '>
              <h2>Order Details</h2>
              <div className='order-info'>
                <p>
                  Order Date:
                  <span className='text-black'>
                    {" "}
                    {order_details?.order_date}
                  </span>
                </p>
                <p>
                  Total Amount:
                  <span className='text-black'>
                    {" "}
                    ${order_details?.total_amount}{" "}
                  </span>
                </p>
                <div className='form-group font-weight-normal  d-flex'>
                  <p
                    className='font-weight-light'
                    htmlFor='tracking-status '
                  >
                    Tracking Status:&nbsp;
                  </p>
                  <span className='text-black'>
                    {" "} {order_details?.tracking_status}
                  </span>
                </div>
                <p>
                  Transaction ID:
                  <span className='text-black'>{" "}
                    {order_details?.transaction_id}
                  </span>
                </p>
                <p>Tracking ID:</p>
                <span>{" "} {order_details?.tracking_id}</span>
                <p>
                  Payment Status:
                  <span>
                    {" "}
                    {order_details?.payment_status ? "done" : "Not done"}
                  </span>
                </p>
                <p>
                  Expected Delivery Date:
                  <span className='text-black'></span>{" "}
                  {order_details?.expected_delivery_on}
                </p>
                <button className='btn text-danger border border-dark-subtle '>
                  Cancel
                </button>
              </div>
            </div>
            <div className='col-lg-3'></div>
            <div className='col-lg-4  col-12 mt-4 pt-2  '>
              <div className=''>
                <h2>Shipping Address</h2>
                <p>
                  {" "}
                  {shipping_address?.first_name} {shipping_address?.last_name},{" "}
                  {shipping_address?.phone_number},{" "}
                  {shipping_address?.ship_address_1},{" "}
                  {shipping_address?.ship_address_2},{" "}
                  {shipping_address?.ship_town_or_city},{" "}
                  {shipping_address?.ship_state_or_region},{" "}
                  {shipping_address?.ship_country},{" "}
                  {shipping_address?.ship_postal_code}{" "}
                </p>
                <div className='mt-4'>
                  <h2>Billing Address</h2>
                  <p>
                    {billing_address?.first_name} {billing_address?.last_name},{" "}
                    {billing_address?.phone_number},{" "}
                    {billing_address?.bill_address_1},{" "}
                    {billing_address?.bill_address_2},{" "}
                    {billing_address?.bill_town_or_city},{" "}
                    {billing_address?.bill_state_or_region},{" "}
                    {billing_address?.bill_country},{" "}
                    {billing_address?.bill_postal_code}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p>Product Details</p>
            <div
              key={product_details?.product_id}
              className='My-orders-wrapper rounded-0 my-md-3'
            >
              <div className='row mb-0'>
                <div className='col-lg-4 d-flex align-items-start'>
                  <div className='row mb-0 mx-md-2'>
                    <div className='col-md-4 p-md-3 p-2 '>
                      <img
                        className='d-block m-auto mb-2'
                        src={product_details?.image}
                        alt={product_details?.name}
                      />
                    </div>
                    <div className='col-md-7  d-flex align-items-center'>
                      <div className=''>
                        <p className='Myorders-sub-head text-secondary mb-1'>
                          {product_details?.sub_category_name === "" ? (
                            <span>{product_details?.category_name}</span>
                          ) : (
                            <span> {product_details?.sub_category_name} </span>
                          )}
                        </p>
                        <p className='text-black'>{product_details?.name}</p>
                        <Link
                          to={`/:category/:subcategory/${product_details?.product_id}`}
                        >
                          {" "}
                          View Product {">"}{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 mb-lg-3 mb-4 d-flex align-items-start'>
                  <div className=''>
                    <div className='w-100'>
                      <p className='Myorders-sub-head text-secondary mb-1'>
                        Details
                      </p>
                      <table className='border-none text-black'>
                        {product_details?.details &&
                          product_details?.details.length > 0 &&
                          product_details?.details
                            .slice(0, 2)
                            .map((data, index) => {
                              const { specKey, specValue } = data;
                              return (
                                <tr key={index}>
                                  <td>{specKey}:</td>
                                  <td>{truncate(specValue, 38)}</td>
                                </tr>
                              );
                            })}
                      </table>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 d-flex align-items-start '>
                  <div className='col-md-4  '>
                    <p className="mb-2">
                      Warranty: <br />
                      {product_details?.warranty_month === 0 && product_details?.warranty_year === 0 ? (
                        <small className="text-black">
                          {" "}
                          Not Applicable{" "}
                        </small>
                      ) : (
                        <span>
                          <p className="text-black mb-1"> {product_details?.warranty_year === 1
                            && `${product_details?.warranty_year} year`}
                            {product_details?.warranty_year > 1 && product_details?.warranty_year !== 0 && `${product_details?.warranty_year} years`}
                            {product_details?.warranty_month === 1 &&
                              `${product_details?.warranty_month} month`}{
                              product_details?.warranty_month > 1 && product_details?.warranty_month !== 0 &&
                              `${product_details?.warranty_month} months`}  </p>
                          <small className="text-warranty ">
                            {product_details?.warrenty_expire_in?.years !== 0 && product_details?.warrenty_expire_in?.months !== 0 & product_details?.warrenty_expire_in?.days !== 0 ? (
                              <div>
                                Warranty expires in <br />{" "}
                                {product_details?.warrenty_expire_in?.years !== 0 ? (
                                  <span>

                                    {product_details?.warrenty_expire_in?.years === 1
                                      ? `${product_details?.warrenty_expire_in?.years} Year`
                                      : `${product_details?.warrenty_expire_in?.years} Years`}
                                  </span>
                                ) : (
                                  " "
                                )}

                                {product_details?.warrenty_expire_in?.months === 1 ||
                                  product_details?.warrenty_expire_in?.months === 1
                                  ? `${product_details?.warrenty_expire_in?.months} month`
                                  : `${product_details?.warrenty_expire_in?.months} months`}{" "}
                                {product_details?.warrenty_expire_in?.days === 1
                                  ? `
                                      ${product_details?.warrenty_expire_in?.days} day
                                      `
                                  :
                                  `${product_details?.warrenty_expire_in?.days} days`

                                }
                              </div>
                            ) : <div> Warranty expired</div>}

                          </small>
                        </span>
                      )}
                    </p>
                  </div>
                  <div className='col-md-4 ms-3'>
                    <div>
                      <p className='Myorders-sub-head text-secondary mb-1'>
                        Price
                      </p>
                      <p className='text-black'>${product_details?.price}</p>
                    </div>
                    <div>
                      <p className='Myorders-sub-head text-secondary mb-1'>
                        Quantity
                      </p>
                      <p className='text-black'>{product_details?.quantity}</p>
                    </div>
                  </div>
                  <div className='col-lg-4 d-flex align-items-center'>
                    <div>
                      <p className='Myorders-sub-head text-secondary mb-1'>
                        Subtotal
                      </p>
                      <p className='text-black'>${product_details?.subtotal}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {other_product_details && other_product_details.length > 0 && (
            <div>
              <p>Other Product Details</p>
              {other_product_details &&
                other_product_details.length > 0 &&
                other_product_details.map((data) => {
                  const {
                    category_name,
                    details,
                    image,
                    name,
                    price,
                    product_id,
                    quantity,
                    sub_category_name,
                    subtotal,
                  } = data;
                  return (
                    <div
                      key={product_id}
                      className='My-orders-wrapper rounded-0 my-md-3'
                    >
                      <div className='row mb-0'>
                        <div className='col-lg-4 d-flex align-items-center'>
                          <div className='row mb-0 mx-md-2'>
                            <div className='col-md-4 p-md-3 p-2 '>
                              <img
                                className='d-block m-auto mb-2'
                                src={image}
                                alt={name}
                              />
                            </div>
                            <div className='col-md-7  d-flex align-items-center'>
                              <div className=''>
                                <p className='Myorders-sub-head text-secondary mb-1'>
                                  {sub_category_name === "" ? (
                                    <span>{category_name}</span>
                                  ) : (
                                    <span> {sub_category_name} </span>
                                  )}
                                </p>
                                <p className='text-black'>{name}</p>
                                <Link
                                  to={`/:category/:subcategory/${product_id}`}
                                >
                                  {" "}
                                  View Product {">"}{" "}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <div className='row mb-0 w-100'>
                            <div className='col-md-6 '>
                              <div className=''>
                                <p className='Myorders-sub-head text-secondary mb-1'>
                                  Details
                                </p>
                                <table className='border-none text-black'>
                                  {details &&
                                    details.length > 0 &&
                                    details.slice(0, 2).map((data, index) => {
                                      const { specKey, specValue } = data;
                                      return (
                                        <tr key={index}>
                                          <td>{specKey} :</td>
                                          <td>{truncate(specValue, 38)}</td>
                                        </tr>
                                      );
                                    })}
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-2 '>
                            <div>
                              <p className='Myorders-sub-head text-secondary mb-1'>
                                Price
                              </p>
                              <p className='text-black'>${price}</p>
                            </div>
                          </div>
                          <div className='col-md-2  '>
                            <div>
                              <p className='Myorders-sub-head text-secondary mb-1'>
                                Quantity
                              </p>
                              <p className='text-black'>{quantity}</p>
                            </div>
                          </div>
                          <div className='col-lg-2 d-flex align-items-center'>
                            <div>
                              <p className='Myorders-sub-head text-secondary mb-1'>
                                Subtotal
                              </p>
                              <p className='text-black'>${subtotal}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          <div className='row'>
            <div className='col-lg-8'></div>
            <div className='col-lg-4 '>
              <div className='cart-total'>
                <h2 className='text-center fw-semibold'>Cart Total</h2>
                <div className='total-info p-3'>
                  <p className='d-flex justify-content-between'>
                    Subtotal:{" "}
                    <strong className='text-black'>${subtotal}</strong>
                  </p>
                  <p className='d-flex justify-content-between'>
                    GST (15%): <strong className='text-black'>$ {gst}</strong>
                  </p>
                  <p className='d-flex justify-content-between'>
                    Shipping:{" "}
                    <strong className='text-black'>${shipping}</strong>
                  </p>
                  <p className='d-flex justify-content-between'>
                    Total Amount:
                    <strong className='text-black'>${total_amount}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default MyOrdersViewPage;
