import { Seo } from "components";
import React from "react";
import { privacyPolicy } from "static/data";

const PrivacyPolicy = () => {
  const intro = `ByteBazaar Ltd ("we," "our," or "us") is committed to protecting your
  privacy. This Privacy Policy is designed to help you understand how we
  collect, use, disclose, and safeguard your personal information when
  you use our online retail store for computing and IT products and
  services. We are dedicated to complying with the New Zealand Privacy
  Act 2020 (the "Act") and other applicable data protection laws.`;
  return (
    <div className='container'>
      <Seo
        metaTitle='Privacy Policy'
        metaDescription='privacy hei'
      />
      <div className='privacy-policy'>
        <h2 classname=' text-center'>Byte Bazaar Privacy Policy</h2>
        <p className='privacy-policy-para-main'>{intro}</p>
        {privacyPolicy && privacyPolicy.length > 0  && privacyPolicy.map((section, index) => (
          <div key={index}>
            <h3 className='privacy-policy-text'>{section.title}</h3>
            {section.description && (
              <p className='privacy-policy-para'>{section.description}</p>
            )}
            {section.subpolicies && (
              <ul>
                {section.subpolicies && section.subpolicies > 0 && section.subpolicies.map((subpolicy, subIndex) => (
                  <li key={subIndex}>
                    {subpolicy.subpolicyHeading}: {subpolicy.subDesc}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
