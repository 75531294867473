import React, { useState, useEffect } from "react";
import { baseURL, API_URL } from "api/apiUrls";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useParams, Outlet } from "react-router-dom";

const OtpPage = () => {
  const [otpValue, setOtpValue] = useState("");
  const [time, setTime] = useState(180);
  const [showResendOTP, setShowResendOTP] = useState(false);
  const [showMsg, setShowMsg] = useState("");

  const params = useParams();
  const { otpType } = params;

  const navigate = useNavigate();
  useEffect(() => {
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          setShowResendOTP(true);
          return 0;
        } else return time - 1;
      });
    }, 1000);
  
    return () => {
      clearInterval(timer); // Clear the interval when unmounting
    };
  }, [time]);

  const handleVerifyOtp = async () => {
    // Implement OTP verification logic here
    try {
      const OTPData = {
        otp: otpValue,
      };
      const resetOtp = {
        reset_otp: otpValue,
      };
      const response = await axios.post(
        `${baseURL}/Users/${otpType}/`,
        otpType === "resetotpverify" ? resetOtp : OTPData,
      );
      if (otpType === "resetotpverify") {
        setShowMsg(response.data?.message);
        sessionStorage.setItem("resetOtpToken", response.data?.data?.token);
        sessionStorage.setItem("resetOtpuid", response.data?.data?.uid);
        setTimeout(() => {
          setShowMsg("");
          navigate("/reset-password");
        }, 2500);
      } else {
        setShowMsg(response.data?.message);
        setTimeout(() => {
          setShowMsg("");
          navigate("/login");
        }, 3000);
      }
    } catch (error) {
      setShowMsg(error.response.data?.message);
      setTimeout(() => {
        setShowMsg("");
      }, 2500);
    }
  };

  const resentOTP = async () => {
    const userEmail = sessionStorage.getItem("userEmail");
    const forgotEmail = sessionStorage.getItem("forgotEmail");
    setTime(180);
    try {
      const resendOTP = {
        email: userEmail,
      };
      const resetresendOTP = {
        email: forgotEmail,
      };

      if (otpType === "resetotpverify") {
        const response = await axios.post(
          API_URL.resetresndOTP,
          resetresendOTP,
        );
        setShowMsg(response.data?.message);
        setTimeout(() => {
          setShowMsg("");
        }, 2500);
      } else {
        const response = await axios.post(API_URL.resendOTP, resendOTP);
        setShowMsg(response.data?.message);
        setTimeout(() => {
          setShowMsg("");
        }, 2500);
      }
    } catch (error) {
      setShowMsg(error.response.data?.message);
      setTimeout(() => {
        setShowMsg("");
      }, 2500);
    }
  };

  return (
    <div className='bac-otp'>
      <div className='otp-container'>
        {showMsg && <div className='alert'>{showMsg}</div>}
        <h1 className='mb-3'>Enter OTP</h1>
        <p className='mb-4'>
          We have Sent an OTP to Your Email address. Please enter here to verify
        </p>
        <div className='text-center'>
          Time left: {`${Math.floor(time / 60)}`.padStart(2, 0)}:
          {`${time % 60}`.padStart(2, 0)}
        </div>
        <div className='otp-input-container'>
          <input
            className='mt-3'
            type='text'
            placeholder='Enter OTP'
            value={otpValue}
            onChange={(e) => setOtpValue(e.target.value)}
          />
          {showResendOTP && (
            <p className='resend-text text-end mt-3'>
              Didn't get OTP?{" "}
              <span
                className='text-primary text-decoration-underline'
                onClick={() => resentOTP()}
              >
                Resend OTP
              </span>
            </p>
          )}
          <button
            className='verify-button'
            onClick={handleVerifyOtp}
          >
            Verify OTP
          </button>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default OtpPage;
