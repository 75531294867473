import React, { useState, useEffect } from "react";
import { API_URL, baseURL } from "api/apiUrls";
import axios from "axios";
import { Addresses, CheckOutSteps, Seo } from "components";

const RegisteredUserAddress = () => {
  const token = sessionStorage.getItem("accessToken");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });

  const [billingData, setBillingData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });

  const [showBill, setShowBill] = useState(true);

  const [userAddress, setUserAddress] = useState([]);
  const [shippingAddress, setShippingAddress] = useState([]);

  const getAddress = async () => {
    try {
      const response = await axios.get(API_URL.userAddress, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      setUserAddress(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddress();
  }, []);

  const loggedInEmail = sessionStorage.getItem("loggedInEmail");

  useEffect(() => {
    const selectedAddress = userAddress?.filter((item) => {
      return item.is_default === true;
    });
    setShippingAddress(selectedAddress);

    setFormData({
      firstName: shippingAddress[0]?.first_name,
      lastName: shippingAddress[0]?.last_name,
      email: loggedInEmail,
      confirmEmail: loggedInEmail,
      phone: shippingAddress[0]?.phone_number,
      addressLine1: shippingAddress[0]?.address_line_1,
      addressLine2: shippingAddress[0]?.address_line_2,
      city: shippingAddress[0]?.city,
      state: shippingAddress[0]?.state,
      postalCode: shippingAddress[0]?.postal_code,
      country: shippingAddress[0]?.country,
    });
  }, [userAddress]);

  const handleBillChange = (e) => {
    const { name, value } = e.target;
    setBillingData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phonePattern = /^[0-9]{10}$/;
    return phonePattern.test(phoneNumber);
  };

  const [validationErrors, setValidationErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (!billingData.firstName) {
      errors.billing_firstName = "First Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(billingData.firstName)) {
      errors.billing_firstName = "Invalid First Name format (letters only)";
    }
    if (!billingData.lastName) {
      errors.billing_lastName = "Last Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(billingData.lastName)) {
      errors.billing_lastName = "Invalid Last Name format (letters only)";
    }
    if (!billingData.email) {
      errors.billing_email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(billingData.email)) {
      errors.billing_email = "Invalid email format";
    }
    if (!billingData.confirmEmail) {
      errors.billing_confirmEmail = "Confirm Email is required";
    } else if (billingData.email !== billingData.confirmEmail) {
      errors.billing_confirmEmail = "Emails do not match";
    }
    if (!billingData.phone) {
      errors.billing_phone = "Phone Number is required";
    } else if (!validatePhoneNumber(billingData.phone)) {
      errors.billing_phone = "Invalid phone number format (10 digits)";
    }
    if (!billingData.addressLine1) {
      errors.billing_addressLine1 = "Address Line 1 is required";
    }
    if (!billingData.addressLine2) {
      errors.billing_addressLine2 = "Address Line 2 is required";
    }
    if (!billingData.city) {
      errors.billing_city = "City is required";
    }
    if (!billingData.state) {
      errors.billing_state = "State is required";
    }
    if (!billingData.postalCode) {
      errors.billing_postalCode = "Postal Code is required";
    } else if (!/^\d{4}$/.test(billingData.postalCode)) {
      errors.billing_postalCode = "Postal Code must be exactly four digits";
    }
    if (!billingData.country) {
      errors.billing_country = "Country is required";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const [orderNo, setorderNo] = useState("");
  const [orderTotal, setOrderTotal] = useState("");
  const orderID = sessionStorage.getItem("orderID");

  useEffect(() => {
    const getOrderDetails = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/Orders/getcheckout/?session_id=&order_id=${orderID}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setorderNo(response.data?.data.invoice_number);
        setOrderTotal(response.data.data.total_amount);
        // setOrderAmount()
      } catch (error) {
        console.error(error.response.data?.message);
      }
    };

    getOrderDetails();
  }, [token, orderID]);

  const handleBillingSubmit = async () => {
    if (validateForm()) {
      const registeredAddress = {
        ship_first_name: formData.firstName,
        ship_last_name: formData.lastName,
        ship_phone_number: formData.phone,
        ship_email: formData.email,
        ship_address_1: formData.addressLine1,
        ship_address_2: formData.addressLine2,
        ship_town_or_city: formData.city,
        ship_state_or_region: formData.state,
        ship_country: formData.country,
        ship_postal_code: formData.postalCode,
        bill_first_name: billingData.firstName,
        bill_last_name: billingData.lastName,
        bill_phone_number: billingData.phone,
        bill_address_1: billingData.addressLine1,
        bill_address_2: billingData.addressLine2,
        bill_town_or_city: billingData.city,
        bill_state_or_region: billingData.state,
        bill_country: billingData.country,
        bill_postal_code: billingData.postalCode,
        type: "purchase",
        amount: `${Number(orderTotal).toFixed(2)}`,
        currency_type: "NZD",
        order_no: `${orderNo}`,
        success_url: `${process.env.REACT_APP_SITE_URL}/order-status/success`,
        failure_url: `${process.env.REACT_APP_SITE_URL}/order-status/failure`,
        cancel_url: `${process.env.REACT_APP_SITE_URL}/order-status/cancel`,
        notification_url: `${process.env.REACT_APP_SITE_URL}/order-status/notification`,
      };
      try {
        const response = await axios.post(
          `${API_URL.userFinalCheckout}?session_id=&order_id=${orderID}`,
          registeredAddress,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const hppUrl = response.data.data.links.find(
          (link) => link.rel === "hpp"
        ).href;

        window.location.href = hppUrl;
      } catch (error) {
        console.error(error.response.data?.message);
      }
    }
  };

  useEffect(() => {
    if (showBill) {
      setBillingData(formData);
    } else {
      setBillingData({
        firstName: "",
        lastName: "",
        email: "",
        confirmEmail: "",
        phone: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
      });
    }
  }, [showBill, formData]);

  return (
    <div>
      <Seo
        metaTitle="Register User"
        metaDescription="Get in touch today!, Need any help please contact us "
      />
      <div className="container ">
        <div className="guest-registered-adress">
          <CheckOutSteps />
          <div className="row">
            <div className="col-lg-1 "></div>
            <div className="col-lg-10 user-content-sec">
              <h1> Address</h1>
              <Addresses token={token} userAddress={userAddress} />
              <h2 className="blling-address pb-2 pt-2 pt-md-5">
                Billing Address
              </h2>
              <div className="mb-3">
                <input
                  className="me-2"
                  type="checkbox"
                  id="address"
                  name="billingSameAsDelivery"
                  checked={showBill}
                  onChange={() => setShowBill(!showBill)}
                ></input>
                <label for="address"> Same as Delivery Address</label>
              </div>
              {!showBill && (
                <div className="col-lg-12 user-content-sec">
                  <div className=" row user-address-form">
                    <div className="col-6 mb-3">
                      <label for="firstname">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        onChange={handleBillChange}
                        value={billingData.firstName}
                        className={`form-control ${
                          validationErrors.billing_firstName ? "is-invalid" : ""
                        }`}
                        id="firstname"
                        placeholder="First Name"
                      ></input>
                      {validationErrors.billing_firstName && (
                        <div className="invalid-feedback">
                          {validationErrors.billing_firstName}
                        </div>
                      )}
                    </div>
                    <div className="col-6 mb-3">
                      {" "}
                      <label for="lastname">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        onChange={handleBillChange}
                        value={billingData.lastName}
                        className={`form-control ${
                          validationErrors.billing_lastName ? "is-invalid" : ""
                        }`}
                        id="lastname"
                        placeholder="Last Name"
                      ></input>
                      {validationErrors.billing_lastName && (
                        <div className="invalid-feedback">
                          {validationErrors.billing_lastName}
                        </div>
                      )}
                    </div>
                    <div className="col-6 mb-3">
                      <label for="email">
                        Email Id <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="email"
                        onChange={handleBillChange}
                        value={billingData.email || loggedInEmail}
                        className={`form-control ${
                          validationErrors.billing_email ? "is-invalid" : ""
                        }`}
                        id="email"
                        placeholder="You@company.com"
                      ></input>
                      {validationErrors.billing_email && (
                        <div className="invalid-feedback">
                          {validationErrors.billing_email}
                        </div>
                      )}
                    </div>
                    <div className="col-6 mb-3">
                      <label for="confirmEmail">
                        Confirm Email Id <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        name="confirmEmail"
                        onChange={handleBillChange}
                        value={billingData.confirmEmail || loggedInEmail}
                        className={`form-control ${
                          validationErrors.billing_confirmEmail
                            ? "is-invalid"
                            : ""
                        }`}
                        id="confirmEmail"
                        aria-describedby="emailHelp"
                        placeholder="You@company.com"
                      ></input>
                      {validationErrors.billing_confirmEmail && (
                        <div className="invalid-feedback">
                          {validationErrors.billing_confirmEmail}
                        </div>
                      )}
                    </div>
                    <div className="col-6 mb-3">
                      <label for="phone">
                        Phone Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="phone"
                        onChange={handleBillChange}
                        value={billingData.phone}
                        className={`form-control ${
                          validationErrors.billing_phone ? "is-invalid" : ""
                        }`}
                        id="phone"
                        placeholder="Enter your address"
                      ></input>
                      {validationErrors.billing_phone && (
                        <div className="invalid-feedback">
                          {validationErrors.billing_phone}
                        </div>
                      )}
                    </div>
                    <div className="col-6"></div>
                    <h3>Address</h3>
                    <div className="col-6 mb-3">
                      <label for="addressline1">
                        Address Line 1 <span className="text-danger">*</span>
                      </label>
                      <input
                        name="addressLine1"
                        onChange={handleBillChange}
                        value={billingData.addressLine1}
                        type="text"
                        className={`form-control ${
                          validationErrors.billing_addressLine1
                            ? "is-invalid"
                            : ""
                        }`}
                        id="addressLine1"
                        placeholder="Enter your address"
                      />{" "}
                      {validationErrors.billing_addressLine1 && (
                        <div className="invalid-feedback">
                          {validationErrors.billing_addressLine1}
                        </div>
                      )}
                    </div>
                    <div className="col-6 mb- ">
                      <label for="addressline2">
                        Address Line 2 <span className="text-danger">*</span>
                      </label>
                      <input
                        name="addressLine2"
                        onChange={handleBillChange}
                        value={billingData.addressLine2}
                        type="text"
                        className={`form-control ${
                          validationErrors.billing_addressLine2
                            ? "is-invalid"
                            : ""
                        }`}
                        id="addressline2"
                        placeholder="Enter your address"
                      />
                      {validationErrors.billing_addressLine2 && (
                        <div className="invalid-feedback">
                          {validationErrors.billing_addressLine2}
                        </div>
                      )}
                    </div>

                    <div className="col-6 mb-3">
                      <label for="inputState">
                        City <span className="text-danger">*</span>
                      </label>
                      <input
                        name="city"
                        onChange={handleBillChange}
                        value={formData.billing_city}
                        type="text"
                        className={`form-control ${
                          validationErrors.billing_city ? "is-invalid" : ""
                        }`}
                        id="city"
                        placeholder="Enter your city"
                      />
                      {validationErrors.billing_city && (
                        <div className="invalid-feedback">
                          {validationErrors.billing_city}
                        </div>
                      )}
                    </div>
                    <div className="col-6 mb-3">
                      <label for="state">
                        State <span className="text-danger">*</span>
                      </label>
                      <input
                        name="state"
                        onChange={handleBillChange}
                        value={formData.billing_state}
                        type="text"
                        className={`form-control ${
                          validationErrors.billing_state ? "is-invalid" : ""
                        }`}
                        id="state"
                        placeholder="Enter your state"
                      />
                      {validationErrors.billing_state && (
                        <div className="invalid-feedback">
                          {validationErrors.billing_state}
                        </div>
                      )}
                    </div>
                    <div className="col-6 mb-3">
                      <label for="postalCode">
                        Postal Code <span className="text-danger">*</span>
                      </label>
                      <input
                        name="postalCode"
                        type="text"
                        className={`form-control ${
                          validationErrors.billing_postalCode
                            ? "is-invalid"
                            : ""
                        }`}
                        id="postalCode"
                        placeholder="****"
                        onChange={handleBillChange}
                      />
                      {validationErrors.postalCode && (
                        <div className="invalid-feedback">
                          {validationErrors.billing_postalCode}
                        </div>
                      )}
                    </div>
                    <div className="col-6 mb-3">
                      <label for="country">
                        Country <span className="text-danger">*</span>
                      </label>
                      <input
                        name="country"
                        onChange={handleBillChange}
                        value={formData.billing_country}
                        type="text"
                        className={`form-control ${
                          validationErrors.billing_country ? "is-invalid" : ""
                        }`}
                        id="country"
                        placeholder="Enter your country"
                      />
                      {validationErrors.billing_country && (
                        <div className="invalid-feedback">
                          {validationErrors.billing_country}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center">
              <div className="col-lg-10 w-100">
                <button
                  className="checkout-address-button "
                  onClick={handleBillingSubmit}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisteredUserAddress;
