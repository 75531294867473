import React, { useState, useEffect } from "react";
import axios from "axios";
import rightarrowSvg from "../assets/icons/rightarrow.svg";
import { baseURL } from "api/apiUrls";
import { CheckOutSteps, Seo } from "components";

const GuestUserAddress = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });

  const [billingData, setBillingData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });

  const validatePhoneNumber = (phoneNumber) => {
    const phonePattern = /^[0-9]{10}$/;
    return phonePattern.test(phoneNumber);
  };
  const [showBill, setShowBill] = useState(true);

  const [validationErrors, setValidationErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBillChange = (e) => {
    const { name, value } = e.target;
    setBillingData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [order, setOrder] = useState([]);

  const sessionID = sessionStorage.getItem("sessionID");
  const orderID = sessionStorage.getItem("orderID");

  useEffect(() => {
    const getOrderDetails = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/Orders/getcheckout/?session_id=${sessionID}&order_id=${orderID}`,
        );
        setOrder(response.data?.data);
        // setOrderAmount()
      } catch (error) {
        console.error(error.response.data?.message);
      }
    };

    getOrderDetails();
  }, []);

  const orderNo = order[order?.length - 1]?.invoice_number;
  const orderTotal = order[order?.length - 1]?.total_amount;

  const handleBillingSubmit = async () => {
    if (validateForm()) {
      const guestAddress = {
        ship_first_name: formData.firstName,
        ship_last_name: formData.lastName,
        ship_phone_number: formData.phone,
        ship_email: formData.email,
        ship_address_1: formData.addressLine1,
        ship_address_2: formData.addressLine2,
        ship_town_or_city: formData.city,
        ship_state_or_region: formData.state,
        ship_country: formData.country,
        ship_postal_code: formData.postalCode,
        bill_first_name: billingData.firstName,
        bill_last_name: billingData.lastName,
        bill_phone_number: billingData.phone,
        bill_address_1: billingData.addressLine1,
        bill_address_2: billingData.addressLine2,
        bill_town_or_city: billingData.city,
        bill_state_or_region: billingData.state,
        bill_country: billingData.country,
        bill_postal_code: billingData.postalCode,
        type: "purchase",
        amount: `${Number(orderTotal).toFixed(2)}`,
        currency_type: "NZD",
        order_no: `${orderNo}`,
        success_url: `${process.env.REACT_APP_SITE_URL}/order-status/success`,
        failure_url: `${process.env.REACT_APP_SITE_URL}/order-status/failure`,
        cancel_url: `${process.env.REACT_APP_SITE_URL}/order-status/cancel`,
        notification_url: `${process.env.REACT_APP_SITE_URL}/order-status/notification`,
      };

      try {
        const response = await axios.post(
          `${baseURL}/Orders/finalcheckout/?session_id=${sessionID}&order_id=${orderID}`,
          guestAddress,
        );
        const hppUrl = response.data.data.links.find(
          (link) => link.rel === "hpp",
        ).href;

        //   Redirect user to Windcave HPP
        window.location.href = hppUrl;
      } catch (error) {
        console.error(error.response.data?.message);
      }
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.firstName) {
      errors.firstName = "First Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(formData.firstName)) {
      errors.firstName = "Invalid First Name format (letters only)";
    }
    if (!formData.lastName) {
      errors.lastName = "Last Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(formData.lastName)) {
      errors.lastName = "Invalid Last Name format (letters only)";
    }
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email format";
    }
    if (!formData.confirmEmail) {
      errors.confirmEmail = "Confirm Email is required";
    } else if (formData.email !== formData.confirmEmail) {
      errors.confirmEmail = "Emails do not match";
    }
    if (!formData.phone) {
      errors.phone = "Phone Number is required";
    } else if (!validatePhoneNumber(formData.phone)) {
      errors.phone = "Invalid phone number format (10 digits)";
    }
    if (!formData.addressLine1) {
      errors.addressLine1 = "Address Line 1 is required";
    }
    if (!formData.addressLine2) {
      errors.addressLine2 = "Address Line 2 is required";
    }
    if (!formData.city) {
      errors.city = "City is required";
    }
    if (!formData.state) {
      errors.state = "State is required";
    }
    if (!formData.postalCode) {
      errors.postalCode = "Postal Code is required";
    } else if (!/^\d{4}$/.test(formData.postalCode)) {
      errors.postalCode = "Postal Code must be exactly four digits";
    }
    if (!formData.country) {
      errors.country = "Country is required";
    }

    if (!billingData.firstName) {
      errors.billing_firstName = "First Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(billingData.firstName)) {
      errors.billing_firstName = "Invalid First Name format (letters only)";
    }
    if (!billingData.lastName) {
      errors.billing_lastName = "Last Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(billingData.lastName)) {
      errors.billing_lastName = "Invalid Last Name format (letters only)";
    }
    if (!billingData.email) {
      errors.billing_email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(billingData.email)) {
      errors.billing_email = "Invalid email format";
    }
    if (!billingData.confirmEmail) {
      errors.billing_confirmEmail = "Confirm Email is required";
    } else if (billingData.email !== billingData.confirmEmail) {
      errors.billing_confirmEmail = "Emails do not match";
    }
    if (!billingData.phone) {
      errors.billing_phone = "Phone Number is required";
    } else if (!validatePhoneNumber(billingData.phone)) {
      errors.billing_phone = "Invalid phone number format (10 digits)";
    }
    if (!billingData.addressLine1) {
      errors.billing_addressLine1 = "Address Line 1 is required";
    }
    if (!billingData.addressLine2) {
      errors.billing_addressLine2 = "Address Line 2 is required";
    }
    if (!billingData.city) {
      errors.billing_city = "City is required";
    }
    if (!billingData.state) {
      errors.billing_state = "State is required";
    }
    if (!billingData.postalCode) {
      errors.billing_postalCode = "Postal Code is required";
    } else if (!/^\d{4}$/.test(billingData.postalCode)) {
      errors.billing_postalCode = "Postal Code must be exactly four digits";
    }
    if (!billingData.country) {
      errors.billing_country = "Country is required";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (showBill) {
      setBillingData(formData);
    } else {
      setBillingData({
        firstName: "",
        lastName: "",
        email: "",
        confirmEmail: "",
        phone: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
      });
    }
  }, [showBill, formData]);

  return (
    <div>
       <Seo
        metaTitle='Guest User'
        metaDescription='Get in touch today!, Need any help please contact us '
      />
      <div className='container '>
        <div className='guest-registered-adress'>
          <CheckOutSteps />
          <div className='row'>
            <div className='col-lg-1 '></div>
            <div className='col-lg-10 user-content-sec'>
              <h1> Address</h1>
              <h2>Shipping Address</h2>
              <div className=' row user-address-form'>
                <div className='col-6 mb-3'>
                  <label htmlFor='firstname'>
                    First Name<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    name='firstName'
                    onChange={handleChange}
                    value={formData.firstName}
                    className={`form-control ${
                      validationErrors.firstName ? "is-invalid" : ""
                    }`}
                    id='firstname'
                    placeholder='First Name'
                  />
                  {validationErrors.firstName && (
                    <div className='invalid-feedback'>
                      {validationErrors.firstName}
                    </div>
                  )}
                </div>
                <div className='col-6 mb-3'>
                  {" "}
                  <label htmlFor='firstname'>
                    Last Name<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    name='lastName'
                    onChange={handleChange}
                    value={formData.lastName}
                    className={`form-control ${
                      validationErrors.lastName ? "is-invalid" : ""
                    }`}
                    id='lastname'
                    placeholder='Last Name'
                  />
                  {validationErrors.lastName && (
                    <div className='invalid-feedback'>
                      {validationErrors.lastName}
                    </div>
                  )}
                </div>
                <div className='col-6 mb-3'>
                  <label htmlFor='email'>
                    Email Id<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='email'
                    name='email'
                    onChange={handleChange}
                    value={formData.email}
                    className={`form-control ${
                      validationErrors.email ? "is-invalid" : ""
                    }`}
                    id='email'
                    placeholder='You@company.com'
                  />
                  {validationErrors.email && (
                    <div className='invalid-feedback'>
                      {validationErrors.email}
                    </div>
                  )}
                </div>
                <div className='col-6 mb-3'>
                  <label htmlFor='confirmEmail'>
                    Confirm Email Id<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='email'
                    name='confirmEmail'
                    onChange={handleChange}
                    value={formData.confirmEmail}
                    className={`form-control ${
                      validationErrors.confirmEmail ? "is-invalid" : ""
                    }`}
                    id='confirmEmail'
                    aria-describedby='emailHelp'
                    placeholder='You@company.com'
                  />
                  {validationErrors.confirmEmail && (
                    <div className='invalid-feedback'>
                      {validationErrors.confirmEmail}
                    </div>
                  )}
                </div>
                <div className='col-6 mb-3'>
                  <label htmlFor='phone'>
                    Phone Number<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    name='phone'
                    onChange={handleChange}
                    value={formData.phone}
                    className={`form-control ${
                      validationErrors.phone ? "is-invalid" : ""
                    }`}
                    id='phone'
                    placeholder='Enter your phone number'
                  />
                  {validationErrors.phone && (
                    <div className='invalid-feedback'>
                      {validationErrors.phone}
                    </div>
                  )}
                </div>
                <div className='col-6'></div>
                <h3>Address</h3>
                <div className='col-6 mb-3'>
                  <label htmlFor='addressline1'>
                    Address Line 1<span className='text-danger'>*</span>
                  </label>
                  <input
                    name='addressLine1'
                    onChange={handleChange}
                    value={formData.addressLine1}
                    type='text'
                    className={`form-control ${
                      validationErrors.addressLine1 ? "is-invalid" : ""
                    }`}
                    id='addressLine1'
                    placeholder='Enter your address'
                  />
                  {validationErrors.addressLine1 && (
                    <div className='invalid-feedback'>
                      {validationErrors.addressLine1}
                    </div>
                  )}
                </div>
                <div className='col-6 mb- '>
                  <label htmlFor='addressline2'>
                    Address Line 2<span className='text-danger'>*</span>
                  </label>
                  <input
                    name='addressLine2'
                    onChange={handleChange}
                    value={formData.addressLine2}
                    type='text'
                    className={`form-control ${
                      validationErrors.addressLine2 ? "is-invalid" : ""
                    }`}
                    id='addressline2'
                    placeholder='Enter your address'
                  />
                  {validationErrors.addressLine2 && (
                    <div className='invalid-feedback'>
                      {validationErrors.addressLine2}
                    </div>
                  )}
                </div>

                <div className='col-6 mb-3'>
                  <label htmlFor='inputState'>
                    City<span className='text-danger'>*</span>
                  </label>

                  <input
                    name='city'
                    onChange={handleChange}
                    value={formData.city}
                    type='text'
                    className={`form-control ${
                      validationErrors.city ? "is-invalid" : ""
                    }`}
                    id='city'
                    placeholder='Enter your city'
                  />
                  {validationErrors.city && (
                    <div className='invalid-feedback'>
                      {validationErrors.city}
                    </div>
                  )}
                </div>
                <div className='col-6 mb-3'>
                  <label htmlFor='inputState'>
                    State<span className='text-danger'>*</span>
                  </label>

                  <input
                    name='state'
                    onChange={handleChange}
                    value={formData.state}
                    type='text'
                    className={`form-control ${
                      validationErrors.state ? "is-invalid" : ""
                    }`}
                    id='state'
                    placeholder='Enter your state'
                  />
                  {validationErrors.state && (
                    <div className='invalid-feedback'>
                      {validationErrors.state}
                    </div>
                  )}
                </div>
                <div className='col-6 mb-3'>
                  <label htmlFor='postalCode'>
                    Postal Code<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    name='postalCode'
                    onChange={handleChange}
                    value={formData.postalCode}
                    className={`form-control ${
                      validationErrors.postalCode ? "is-invalid" : ""
                    }`}
                    id='postalCode'
                    placeholder='Enter your postal code'
                  />
                  {validationErrors.postalCode && (
                    <div className='invalid-feedback'>
                      {validationErrors.postalCode}
                    </div>
                  )}
                </div>
                <div className='col-6 mb-3'>
                  <label htmlFor='country'>
                    Country<span className='text-danger'>*</span>
                  </label>

                  <input
                    type='text'
                    name='country'
                    onChange={handleChange}
                    value={formData.country}
                    className={`form-control ${
                      validationErrors.country ? "is-invalid" : ""
                    }`}
                    id='country'
                    placeholder='Enter your country'
                  />
                  {validationErrors.country && (
                    <div className='invalid-feedback'>
                      {validationErrors.country}
                    </div>
                  )}
                </div>

                <h2 className='blling-address pb-2 pt-2 pt-md-5 '>
                  Billing Address
                </h2>
                <div className='mb-3'>
                  <input
                    className='me-2'
                    type='checkbox'
                    id='address'
                    name='billingSameAsDelivery'
                    checked={showBill}
                    onChange={() => setShowBill(!showBill)}
                  ></input>
                  <label htmlFor='address'> Same as Delivery Address</label>
                </div>
                {!showBill && (
                  <div className='col-lg-12 user-content-sec'>
                    <div className=' row user-address-form'>
                      <div className='col-6 mb-3'>
                        <label htmlFor='firstname'>
                          First Name<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          name='firstName'
                          onChange={handleBillChange}
                          value={billingData.firstName}
                          className={`form-control ${
                            validationErrors.billing_firstName
                              ? "is-invalid"
                              : ""
                          }`}
                          id='firstname'
                          placeholder='First Name'
                        />
                        {validationErrors.billing_firstName && (
                          <div className='invalid-feedback'>
                            {validationErrors.billing_firstName}
                          </div>
                        )}
                      </div>
                      <div className='col-6 mb-3'>
                        {" "}
                        <label htmlFor='lastname'>
                          Last Name<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          name='lastName'
                          onChange={handleBillChange}
                          value={billingData.lastName}
                          className={`form-control ${
                            validationErrors.billing_lastName
                              ? "is-invalid"
                              : ""
                          }`}
                          id='lastname'
                          placeholder='Last Name'
                        />
                        {validationErrors.billing_lastName && (
                          <div className='invalid-feedback'>
                            {validationErrors.billing_lastName}
                          </div>
                        )}
                      </div>
                      <div className='col-6 mb-3'>
                        <label htmlFor='email'>
                          Email Id<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='email'
                          name='email'
                          onChange={handleBillChange}
                          value={billingData.email}
                          className={`form-control ${
                            validationErrors.billing_email ? "is-invalid" : ""
                          }`}
                          id='email'
                          placeholder='You@company.com'
                        />
                        {validationErrors.email && (
                          <div className='invalid-feedback'>
                            {validationErrors.billing_email}
                          </div>
                        )}
                      </div>
                      <div className='col-6 mb-3'>
                        <label htmlFor='confirmEmail'>Confirm Email Id*</label>
                        <input
                          type='email'
                          name='confirmEmail'
                          onChange={handleBillChange}
                          value={billingData.confirmEmail}
                          className={`form-control ${
                            validationErrors.billing_confirmEmail
                              ? "is-invalid"
                              : ""
                          }`}
                          id='confirmEmail'
                          aria-describedby='emailHelp'
                          placeholder='You@company.com'
                        />
                        {validationErrors.confirmEmail && (
                          <div className='invalid-feedback'>
                            {validationErrors.billing_confirmEmail}
                          </div>
                        )}
                      </div>
                      <div className='col-6 mb-3'>
                        <label htmlFor='phone'>
                          Phone Number<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          name='phone'
                          onChange={handleBillChange}
                          value={billingData.phone}
                          className={`form-control ${
                            validationErrors.billing_phone ? "is-invalid" : ""
                          }`}
                          id='phone'
                          placeholder='Enter your phone number'
                        />
                        {validationErrors.billing_phone && (
                          <div className='invalid-feedback'>
                            {validationErrors.billing_phone}
                          </div>
                        )}
                      </div>
                      <div className='col-6'></div>
                      <h3>Address</h3>
                      <div className='col-6 mb-3'>
                        <label htmlFor='addressline1'>
                          Address Line 1<span className='text-danger'>*</span>
                        </label>
                        <input
                          name='addressLine1'
                          onChange={handleBillChange}
                          value={billingData.addressLine1}
                          type='text'
                          className={`form-control ${
                            validationErrors.billing_addressLine1
                              ? "is-invalid"
                              : ""
                          }`}
                          id='addressLine1'
                          placeholder='Enter your address'
                        />
                        {validationErrors.billing_addressLine1 && (
                          <div className='invalid-feedback'>
                            {validationErrors.billing_addressLine1}
                          </div>
                        )}
                      </div>
                      <div className='col-6 mb- '>
                        <label htmlFor='addressline2'>
                          Address Line 2<span className='text-danger'>*</span>
                        </label>
                        <input
                          name='addressLine2'
                          onChange={handleBillChange}
                          value={billingData.billing_addressLine2}
                          type='text'
                          className={`form-control ${
                            validationErrors.billing_addressLine2
                              ? "is-invalid"
                              : ""
                          }`}
                          id='addressline2'
                          placeholder='Enter your address'
                        />
                        {validationErrors.billing_addressLine2 && (
                          <div className='invalid-feedback'>
                            {validationErrors.billing_addressLine2}
                          </div>
                        )}
                      </div>

                      <div className='col-6 mb-3'>
                        <label htmlFor='inputState'>
                          City<span className='text-danger'>*</span>
                        </label>

                        <input
                          name='city'
                          onChange={handleBillChange}
                          value={formData.billing_city}
                          type='text'
                          className={`form-control ${
                            validationErrors.billing_city ? "is-invalid" : ""
                          }`}
                          id='city'
                          placeholder='Enter your city'
                        />
                        {validationErrors.billing_city && (
                          <div className='invalid-feedback'>
                            {validationErrors.billing_city}
                          </div>
                        )}
                      </div>
                      <div className='col-6 mb-3'>
                        <label htmlFor='state'>
                          State<span className='text-danger'>*</span>
                        </label>
                        <input
                          name='state'
                          onChange={handleBillChange}
                          value={formData.billing_state}
                          type='text'
                          className={`form-control ${
                            validationErrors.billing_state ? "is-invalid" : ""
                          }`}
                          id='state'
                          placeholder='Enter your state'
                        />
                        {validationErrors.billing_state && (
                          <div className='invalid-feedback'>
                            {validationErrors.billing_state}
                          </div>
                        )}
                      </div>
                      <div className='col-6 mb-3'>
                        <label htmlFor='postalCode'>
                          Postal Code<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          name='postalCode'
                          onChange={handleBillChange}
                          value={billingData.postalCode}
                          className={`form-control ${
                            validationErrors.billing_postalCode
                              ? "is-invalid"
                              : ""
                          }`}
                          id='postalCode'
                          placeholder='Enter your postal code'
                        />
                        {validationErrors.billing_postalCode && (
                          <div className='invalid-feedback'>
                            {validationErrors.billing_postalCode}
                          </div>
                        )}
                      </div>
                      <div className='col-6 mb-3'>
                        <label htmlFor='country'>
                          Country<span className='text-danger'>*</span>
                        </label>
                        <input
                          name='country'
                          onChange={handleBillChange}
                          value={formData.billing_country}
                          type='text'
                          className={`form-control ${
                            validationErrors.billing_country ? "is-invalid" : ""
                          }`}
                          id='country'
                          placeholder='Enter your country'
                        />
                        {validationErrors.billing_country && (
                          <div className='invalid-feedback'>
                            {validationErrors.billing_country}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <button
                className='address-button'
                onClick={handleBillingSubmit}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestUserAddress;
