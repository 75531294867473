import React from "react";
import { Link } from "react-router-dom";

function CalltoAction() {
  return (
    <>
      <div className='callToAction d-flex justify-content-center '>
        <div className='callToAction-inner m-md-5 p-md-5 p-3 text-center'>
          <div className='px-md-5 mx-lg-5'>
            <h2 className='fw-semibold'>Need Help ?</h2>
            <p className=''>Please reach out here</p>
            <Link
              to='/contact-us'
              className='btn text-decoration-none text-white fw-semibold'
            >
              Reach Us Now
            </Link>
            <p className='mt-3'>Our agent will Get Back to You</p>
          </div>
        </div>
      </div>
    </>
  );
}

export { CalltoAction };
