import React from "react";
// import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function TopCategories({ data }) {
  return (
    <>
      <div className='topcategories mt-lg-5'>
        <div className='section-head my-3'>
          <h2 className='fw-semibold pb-md-auto pb-0'>Top Categories</h2>
        </div>
        <Swiper
          className='topcategories-swiper'
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            440: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1440: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          speed={1000}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {data && data.length > 0 && data?.map((item) => {
            const { id, image, name } = item;
            return (
              <SwiperSlide
                className='topcategories-swiper'
                key={id}
              >
                {/* <Link className="text-decoration-none" to={link}> */}
                <Link
                  to={`/${name.split(" ").join("-").toLowerCase()}`}
                  className='topcategories-swiper-slide-inner text-decoration-none'
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${image})`,
                  }}
                >
                  <h5 className='fw-semibold text-white text-center'>{name}</h5>
                </Link>
                {/* </Link> */}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}

export { TopCategories };
