import React, { useState } from "react";
import axios from "axios";
import { API_URL } from "api/apiUrls";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "routes/navigation";
import eyeiconSvg from "../assets/icons/eye-icon-show.svg";
import eyeiconshowSvg from "../assets/icons/eye-icon.svg";
import { Seo, GoogleLoginBtn, FacebookLoginBtn } from "components";

const Login = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const validatePassword = () => {
    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
    } else {
      setPasswordError("");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform form validations before submitting
    validateEmail();
    validatePassword();

    // If there are no errors, proceed with form submission
    if (!emailError && !passwordError) {
      // Perform form submission or API call here
      try {
        const loginData = {
          email: email,
          password: password,
        };
        const response = await axios.post(API_URL.login, loginData);
        if (
          response.data?.message === "Login Success" &&
          window.location.pathname === "/guest-login"
        ) {
          sessionStorage.setItem("accessToken", response.data?.data.access);
          sessionStorage.setItem("loggedInUser", response.data?.data.user_name);
          sessionStorage.setItem(
            "loggedInEmail",
            response.data?.data.user_email,
          );
          navigate("/registered-address");
        }
        else if (response.data?.message === "Login Success") {
          sessionStorage.setItem("accessToken", response.data?.data.access);
          sessionStorage.setItem("loggedInUser", response.data?.data.user_name);
          sessionStorage.setItem(
            "loggedInEmail",
            response.data?.data.user_email,
          );
          navigate("/");
          window.location.reload(true);
        }
      } catch (error) {
        setErrorMessage(error.response.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 2500);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };


  return (
    <div className='background'>
       <Seo
        metaTitle='Login'
        metaDescription='Get in touch today!, Need any help please contact us '
      />
      <div className='Login-form'>
        <div className='side-heading'>Sign In</div>
        <div className='sub-text'>
          Welcome back! Please sign in to your account.
        </div>
        {errorMessage && (
          <div
            className='alert alert-danger text-center alert-dismissible fade show'
            role='alert'
          >
            <strong>{errorMessage}</strong>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className='input-group'>
            <label>Email ID</label>
            <input
              className='position-relative'
              type='email'
              placeholder='Enter your email'
              value={email}
              onChange={handleEmailChange}
              onBlur={validateEmail}
              required
            />
            {emailError && <span className='error-text'>{emailError}</span>}
          </div>
          <div className='input-group d-flex justify-content-between'>
            <div>
              <label>Password</label>
            </div>
            <div className='forgot-password-link'>
              <Link to={routes.forgotPassword}>Forgot Password?</Link>
            </div>
            <input
              className=''
              type={showPassword ? "text" : "password"}
              placeholder='Enter your password'
              value={password}
              onChange={handlePasswordChange}
              onBlur={validatePassword}
              required
            />
            <span
              className='eye-icon'
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <img
                  src={eyeiconshowSvg}
                  alt='show eye'
                />
              ) : (
                <img
                  src={eyeiconSvg}
                  alt='hide eye'
                />
              )}
            </span>
          </div>
          {passwordError && <p className='text-danger'>{passwordError}</p>}
          <button
            type='submit'
            className='signin-btn'
          >
            Sign In
          </button>
        </form>
        <div className='sub-text text-center mt-4'>
          Don't have an account?{" "}
          <span>
            <Link to={routes.register}>Create one</Link>
          </span>
        </div>
        <div >
          <h6 className="text-center mb-0 fw-bold">Continue with</h6>
          
         <div className='social-buttons'>
         <GoogleLoginBtn />
            <FacebookLoginBtn />
         </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
