import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FacebookSvg from "assets/icons/facebook-176-svgrepo-com.svg";
import { LoginSocialFacebook } from "reactjs-social-login";
import { API_URL } from "api/apiUrls";

const FacebookLoginBtn = () => {
  const navigate = useNavigate();

  const handleLogin = async (response) => {
    console.log(response);
    if (response) {
      try {
        const facebookData = {
          login_type: "facebook",
          email: response?.data?.email,
          social_id: `${response?.data?.userID}`,
          first_name: response?.data?.first_name,
          last_name: response?.data?.last_name,
        };
        console.log(facebookData);

        const res = await axios.post(API_URL.facebookLogin, facebookData);
        sessionStorage.setItem("accessToken", res.data?.data?.access);
        sessionStorage.setItem("loggedInUser", res.data?.data?.user_name);
        sessionStorage.setItem("loggedInEmail", res.data?.data?.user_email);
        if (
          res.data?.message === "Login Success with Facebook" &&
          window.location.pathname === "/guest-login"
        ) {
          navigate("/registered-address");
        } else if (res.data?.message === "Login Success with Facebook") {
          navigate("/");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <LoginSocialFacebook appId="797710828688186" onResolve={handleLogin}>
        <button
          type="button"
          className="Social-btn-container-inner-icons-facebook"
        >
          <img src={FacebookSvg} alt="Facebook" />
        </button>
      </LoginSocialFacebook>
    </div>
  );
};

export { FacebookLoginBtn };
