import React, { useState } from "react";
import PhoneSvg from "../assets/icons/phone.svg";
import MapSvg from "../assets/icons/map.svg";
import EnvelopSvg from "../assets/icons/envelope.svg";
import TwitterSvg from "../assets/icons/twitter.svg";
import FacebookSvg from "../assets/icons/facebook.svg";
import InstagramSvg from "../assets/icons/instagram.svg";
import LinkdinSvg from "../assets/icons/linkedin.svg";
import axios from "axios";
import { API_URL } from "api/apiUrls";
import { Seo } from "components";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [responseMsg, setResponseMsg] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "name") setName(value);
    else if (name === "email") setEmail(value);
    else if (name === "phone") setPhone(value);
    else if (name === "message") setMessage(value);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!name.trim()) {
      newErrors.name = "Name is required";
    } else if (name.length < 3) {
      newErrors.name = "Name must be at least 3 characters long";
    } else if (!/^[A-Za-z\s]+$/.test(name)) {
      newErrors.name = "Name must contain only alphabets";
    }
    if (!email.trim()) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(email))
      newErrors.email = "Invalid email address";
    if (!phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^[0-9]{10}$/.test(phone)) {
      newErrors.phone =
        "Invalid phone number. It must be exactly 10 digits long and contain only numerical digits";
    }

    if (!message.trim()) newErrors.message = "Message is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      // Handle form submission here

      try {
        const contactData = {
          name: name,
          from_email: email,
          phone_no: phone,
          message: message,
        };

        const response = await axios.post(API_URL.contact, contactData);
        setResponseMsg(response.data?.message);
        setTimeout(() => {
          setResponseMsg("");
        }, 3000);
      } catch (error) {
        setResponseMsg(error.response.data?.message);
        setTimeout(() => {
          setResponseMsg("");
        }, 3000);
      }
    }
  };

  return (
    <div className='container-fluid'>
      <Seo
        metaTitle='Contact Us'
        metaDescription='Get in touch today!, Need any help please contact us '
      />
      <div className='row banner '>
        <div className='col-lg-6 '>
          <div className='contact-content'>
            <h3 className='contact-h3'>Contact Us</h3>
            <h2 className='contact-h2'>Get in touch today!</h2>
            <p className='contact-p'>
              Connect with us for a seamless experience! Reach out to us now and
              let's start a conversation.
            </p>
            <div className='social-icons'>
              <img
                src={TwitterSvg}
                alt='Twitter'
              ></img>
              <img
                src={FacebookSvg}
                alt='Facebook'
              ></img>
              <img
                src={InstagramSvg}
                alt='Instagram'
              ></img>
              <img
                src={LinkdinSvg}
                alt='LinkedIn'
              ></img>
            </div>
          </div>
        </div>
        <div className='col-lg-6 banner-image'>
          {/* Add any content or images here if needed */}
        </div>
      </div>

      <div className='row d-flex justify-content-center'>
        <h3 className='text-center'>VISIT OUR OFFICE</h3>
        <h2 className='text-center px-lg-4'>
          Discover the world of technology at our virtual doorstep!
        </h2>
        <div className='col-lg-3 text-center'>
          <div className='boxex-contact'>
            <img
              src={PhoneSvg}
              alt='Phone'
            ></img>
            <p>+ 9354476588</p>
            <p>040 - 5437654</p>
          </div>
        </div>
        <div className='col-lg-3 text-center'>
          <div className='boxex-contact'>
            <img
              src={MapSvg}
              alt='Map'
            ></img>
            <p>9 Manuka street Tauhara</p>
            <p>Taupo New Zealand</p>
          </div>
        </div>
        <div className='col-lg-3 text-center'>
          <div className='boxex-contact'>
            <img
              src={EnvelopSvg}
              alt='Envelope'
            ></img>
            <p>Demo@example.com</p>
            <p>demo@exe.com</p>
          </div>
        </div>
      </div>

      <div className='row contactus-section-map d-flex justify-content-center'>
        <div className='col-lg-5'>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12456.60554960853!2d176.1053184!3d-38.6913667!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d6bfa129488d649%3A0x42941fa4f7cbcfac!2s9%20M%C4%81nuka%20Street%2C%20Tauhara%2C%20Taup%C5%8D%203378%2C%20New%20Zealand!5e0!3m2!1sen!2sin!4v1690438583909!5m2!1sen!2sin'
            width='100%'
            height='100%'
            style={{ border: "0" }}
            allowFullScreen
            loading='lazy'
            title='Google Maps'
            referrerPolicy='no-referrer-when-downgrade'
          ></iframe>
        </div>
        <div className='col-lg-1'></div>
        <div className='col-lg-5 mt-4 mt-lg-0'>
          <h3 className='mb-3'>Contact Us Form</h3>
          <h2>We're just a click away! Connect with us for all your needs</h2>
          {responseMsg && (
            <div className='alert alert-primary'>{responseMsg}</div>
          )}
          <form onSubmit={handleSubmit}>
            <div className='form-group'>
              <label htmlFor='name'>
                Name<span className='astrix-s'>*</span>{" "}
              </label>
              <input
                type='text'
                className={`form-control ${errors.name ? "is-invalid" : ""}`}
                id='name'
                name='name'
                value={name}
                onChange={handleInputChange}
                placeholder='Enter your name'
              />
              {errors.name && (
                <div className='invalid-feedback'>{errors.name}</div>
              )}
            </div>
            <div className='form-group'>
              <label htmlFor='email'>
                Email <span className='astrix-s'>*</span>
              </label>
              <input
                type='email'
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                id='email'
                name='email'
                value={email}
                onChange={handleInputChange}
                placeholder='Enter your email'
              />
              {errors.email && (
                <div className='invalid-feedback'>{errors.email}</div>
              )}
            </div>
            <div className='form-group'>
              <label htmlFor='phone'>
                Phone Number <span className='astrix-s'>*</span>
              </label>
              <input
                type='tel'
                className={`form-control ${errors.phone ? "is-invalid" : ""}`}
                id='phone'
                name='phone'
                value={phone}
                onChange={handleInputChange}
                placeholder='Enter your phone number'
              />
              {errors.phone && (
                <div className='invalid-feedback'>{errors.phone}</div>
              )}
            </div>
            <div className='form-group'>
              <label htmlFor='message'>
                Message <span className='astrix-s'>*</span>
              </label>
              <textarea
                className={`form-control ${errors.message ? "is-invalid" : ""}`}
                id='message'
                name='message'
                value={message}
                onChange={handleInputChange}
                rows='3'
                placeholder='Enter your message'
              />
              {errors.message && (
                <div className='invalid-feedback'>{errors.message}</div>
              )}
            </div>
            <button
              type='submit'
              className='btn btn-primary'
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
