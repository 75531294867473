import React, { useState } from "react";
import axios from "axios";
import { API_URL } from "api/apiUrls";
import { Link, useNavigate } from "react-router-dom";
import { FacebookLoginBtn, GoogleLoginBtn } from "components";
import { Seo } from "components";


const Register = () => {

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    agreed: false,
  });
  const [errors, setErrors] = useState({});
  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAgreedChange = (e) => {
    setFormData({
      ...formData,
      agreed: e.target.checked,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (formData.firstName.trim() === "") {
      newErrors.firstName = "Please enter your First Name.";
    }

    if (formData.lastName.trim() === "") {
      newErrors.lastName = "Please enter your Last Name.";
    }

    if (formData.email.trim() === "") {
      newErrors.email = "Please enter your Email ID.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Please enter a valid Email ID.";
    }

    if (formData.phone.trim() === "") {
      newErrors.phone = "Please enter your Phone Number.";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Please enter a valid 10-digit Phone Number.";
    }

    if (formData.password.trim() === "") {
      newErrors.password = "Please enter a Password.";
    } else if (formData.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long.";
    }

    if (formData.confirmPassword.trim() === "") {
      newErrors.confirmPassword = "Please confirm your Password.";
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }

    if (!formData.agreed) {
      newErrors.agreed = "Please agree to the terms and conditions.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    const isValid = validateForm();

    if (isValid) {
      try {
        const registrationData = {
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          password: formData.password,
          password2: formData.confirmPassword,
          phone_number: formData.phone,
        };
        sessionStorage.setItem("userEmail", formData.email);

        const response = await axios.post(API_URL.register, registrationData);
        if (response.data) {
          setErrorMsg(response.data?.message);
          navigate("/otp/activate");
          setTimeout(() => {
            setErrorMsg("");
          }, 2500);
        }
      } catch (error) {
        setErrorMsg(error.response.data?.message);
        setTimeout(() => {
          setErrorMsg("");
        }, 2500);
      }
    }
  };

  return (
    <div className='background'>
       <Seo
        metaTitle='Register'
        metaDescription='Get in touch today!, Need any help please contact us '
      />
      <div className='register-page'>
        <h1>Create an Account</h1>
        {errorMsg && <div className='alert'>{errorMsg}</div>}
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-12'>
            <label htmlFor=''>
              First Name<span className='span1'>*</span>
            </label>
            <input
              type='text'
              placeholder='First Name'
              name='firstName'
              value={formData.firstName}
              onChange={handleChange}
            />
            {errors.firstName && <p className='error'>{errors.firstName}</p>}
          </div>
          <div className='col-lg-6 col-md-6 col-12'>
            <label htmlFor=''>
              Last Name<span className='span1'>*</span>
            </label>
            <input
              type='text'
              placeholder='Last Name'
              name='lastName'
              value={formData.lastName}
              onChange={handleChange}
            />
            {errors.lastName && <p className='error'>{errors.lastName}</p>}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12 col-12'>
            <label htmlFor=''>
              Email Id<span className='span1'>*</span>
            </label>
            <input
              type='email'
              placeholder='xyz@domain.com'
              name='email'
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p className='error'>{errors.email}</p>}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12  col-12'>
            <label htmlFor=''>
              Phone Number<span className='span1'>*</span>
            </label>
            <input
              type='tel'
              placeholder='Phone Number'
              name='phone'
              value={formData.phone}
              onChange={handleChange}
            />
            {errors.phone && <p className='error'>{errors.phone}</p>}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12  col-12 position-relative'>
            <label htmlFor=''>
              Create Password<span className='span1'>*</span>
            </label>
            <input
              type={showPassword ? "text" : "password"}
              placeholder='Create Password'
              name='password'
              value={formData.password}
              onChange={handleChange}
            />
            {errors.password && <p className='error'>{errors.password}</p>}
            <span
              className={`eye-icon ${showPassword ? "show" : ""}`}
              onClick={toggleShowPassword}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12  col-12 position-relative'>
            <label htmlFor=''>
              Confirm Password<span className='span1'>*</span>
            </label>
            <input
              type={showConfirmPassword ? "text" : "password"}
              placeholder='Confirm Password'
              name='confirmPassword'
              value={formData.confirmPassword}
              onChange={handleChange}
            />
            {errors.confirmPassword && (
              <p className='error'>{errors.confirmPassword}</p>
            )}
            <span
              className={`eye-icon ${showConfirmPassword ? "show" : ""}`}
              onClick={toggleShowConfirmPassword}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12  col-12 d-flex align-items-center'>
            <input
              type='checkbox'
              name='agreed'
              checked={formData.agreed}
              onChange={handleAgreedChange}
            />
            <label className='ms-1 mb-0'>
              <span>Agree to terms and conditions</span>
            </label>
          </div>
          {errors.agreed && <p className='error'>{errors.agreed}</p>}
        </div>
        <div className='row'>
          <div className='col-lg-12 col-12'>
            <button
              type='submit'
              onClick={handleSubmit}
            >
              Register
            </button>
          </div>
        </div>
        <div className='row text-center mt-4'>
          <p>
            Already have an Account? <Link to='/login'>Sign In</Link>{" "}
          </p>
        </div>
        <div >
          <h6 className="text-center mb-0 fw-bold">Continue with</h6>
          
         <div className='social-buttons'>
         <GoogleLoginBtn />
            <FacebookLoginBtn />
         </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
