import React from "react";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function Banner({data}) {
  return (
    <>
      <div className="overflow-hidden">
      <Swiper
        className="banner-swiper "
        spaceBetween={50}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        speed={900}
        loop={true}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {data && data.length > 0 && data?.map((item) => {
          const { id, image, url } = item;
          return (
            <SwiperSlide key={id}>
              <Link to={url}>
                <div className="banner-swiper-slide">
                  <img
                    className="img-fluid"
                    src={image}
                    alt="Slider-1"
                  />
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
      </div>
    </>
  );
}

export { Banner };
