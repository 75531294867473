import React from "react";

const SortingDropdown = ({
  setSorted,
  products,
  sortOptions,
  title,
  setGstType,
  gstType,
}) => {
  return (
    <div className='container p-0 d-flex justify-content-center'>
      <div className='w-100 p-2 px-2 sorting-Align d-flex justify-content-between'>
        <div className='col-lg-5 col-6 col-md-6 align-items-center d-flex justify-content-start'>
          {products && (
            <h3 className='ps-2  pt-0 mb-0 sort-font'>
              {products.length} {title}
            </h3>
          )}
        </div>

        <div className='col-6 align-items-center d-flex justify-content-end'>
          {title !== "Orders" && (
            <div className='sorting-dropdown2 '>
              <select
                className='mt-0'
                value={gstType}
                onChange={(e) => setGstType(e.target.value)}
              >
                <option value=''> -GST Type-</option>
                <option value='exc'>GST Excluded</option>
                <option value='inc'>GST Included</option>
              </select>
            </div>
          )}
          <div className='sorting-dropdown ms-2'>
            <select
              className='mt-0'
              onChange={(e) => setSorted(e.target.value)}
            >
              <option value=''> -Sort by-</option>
              {sortOptions &&
                sortOptions.length > 0 &&
                sortOptions.map((item) => {
                  const { value, view } = item;
                  return (
                    <option
                      value={value}
                      key={view}
                    >
                      {view}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SortingDropdown };
