import React from "react";
import { companyInfo, socialMediaLInks } from "static/data";
import Twitter from "assets/icons/footer-twitter.svg";
import Instgram from "assets/icons/footer-insta.svg";
import Facebook from "assets/icons/footer-fb.svg";
import Phone from "assets/icons/footer-phone.svg";
import Maps from "assets/icons/footer-map.svg";
import Mail from "assets/icons/footer-mail.svg";
import { Link } from "react-router-dom";

const Footer = ({ headerNav }) => {
  return (
   <div className="footer-container">
     <div className='container'>
      <div className='row footer-columns-container'>
        <div className='col-lg-3 col-md-6  footer-column'>
          <p>About Us</p>
          <div className='footer-column-about'>
            Byte Bazaar is a Leading Electronics company in New Zealand
          </div>
          <div className='footer-column-social-media'>
            {socialMediaLInks &&
              socialMediaLInks.length > 0 &&
              socialMediaLInks.map((item) => {
                const { link, icon } = item;
                let Icon;
                switch (icon) {
                  case "facebook":
                    Icon = Facebook;

                    break;
                  case "instagram":
                    Icon = Instgram;

                    break;
                  case "twitter":
                    Icon = Twitter;

                    break;

                  default:
                    break;
                }
                return (
                  <a
                    href={link}
                    key={icon}
                  >
                    <div>
                      <img
                        src={Icon}
                        alt={icon}
                      />
                    </div>
                  </a>
                );
              })}
          </div>
        </div>
        <div className='col-lg-3 col-md-6 footer-column'>
          <p>Company Info</p>
          <div>
            {companyInfo &&
              companyInfo.length > 0 &&
              companyInfo.map((item) => {
                return (
                  <a
                    href={item.link}
                    key={item.item}
                  >
                    <div>{item.item}</div>
                  </a>
                );
              })}
          </div>
        </div>
        {headerNav?.category_subCategory?.length > 0 && (
          <div className='col-lg-3 col-md-6 footer-column'>
            <p>Shopping Links</p>
            <div>
              {headerNav?.category_subCategory?.slice(0, 6)?.map((item) => {
                return (
                  <Link
                    to={`/${item?.name.split(" ").join("-").toLowerCase()}`}
                    key={item.name}
                  >
                    <div className='text-truncate'>{item.name}</div>
                  </Link>
                );
              })}
            </div>
          </div>
        )}
        <div className='col-lg-3 col-md-6 footer-column'>
          <p>Get In Touch</p>
          <div className='footer-column-inner'>
            <div className='footer-column-inner-item'>
              <img
                src={Phone}
                alt='phone'
              />
              <a href='tel:+ (480) 555 1013'> (480) 555 1013</a>
            </div>
            <div className='footer-column-inner-item'>
              <img
                src={Maps}
                alt=''
              />
              <a href='/'> 4517 Washington Ave</a>
            </div>
            <div className='footer-column-inner-item'>
              <img
                src={Mail}
                alt=''
              />
              <a href='mail:johndoe@gmail.com'> debraholt@example.com</a>
            </div>
          </div>
        </div>
      </div>
      <div className='text-center fw-bold p-3 footer-tag'>
        Powered by{" "}
        <a
          className='footer-text'
          href='https://www.avtrix.co.nz/'
          target='_blank'
          rel='noreferrer'
        >
          Avtrix Software Solutions
        </a>
      </div>
    </div>
   </div>
  );
};

export { Footer };
