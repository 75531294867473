import { Seo } from "components";
import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className='my-5 text-center'>
       <Seo
        metaTitle='PAGE NOT FOUND!'
        metaDescription='Get in touch today!, Need any help please contact us '
      />
      <h2>404 Page Not Found !!</h2>
      <div>
        <Link
          to='/'
          className='btn btn-primary'
        >
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
