import React, { useState, useEffect } from "react";

const ProductImages = ({ images }) => {
  const [productImages, setProductImages] = useState([]);
  const [mainImage, setMainImage] = useState("");
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [isOutsideBoxVisible, setIsOutsideBoxVisible] = useState(false);

  useEffect(() => {
    setProductImages(images);
    setMainImage(productImages && productImages[0]?.image);
  }, [images, productImages]);

  const handleThumbnailClick = (image) => {
    setMainImage(image);
    setIsOutsideBoxVisible(false); // Hide the outside box when switching images
  };

  const handleImageHover = () => {
    setIsOutsideBoxVisible(true); // Show the outside box when hovering over the main image
  };

  const handleImageMouseMove = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;
    setCursorPosition({ x: offsetX, y: offsetY });
  };

  const handleMouseLeave = () => {
    setIsOutsideBoxVisible(false); // Hide the outside box when the cursor leaves the main image
  };

  return (
    <div className="Product-image-gallery">
      <div className="row flex-sm-reverse">
        <div className="col-md-2">
          <div className="Product-image-gallery-thumb d-flex justify-content-center align-items-center h-100">
            <div>
              {productImages &&
                productImages.length > 0 &&
                productImages.slice(0, 4).map((item, index) => (
                  <img
                    key={index}
                    src={item.image}
                    alt={`Thumbnail ${index + 1}`}
                    onClick={() => handleThumbnailClick(item.image)}
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="col-md-8">
          {mainImage && (
            <div
              className="Product-image-gallery-Main"
              onMouseEnter={handleImageHover}
              onMouseMove={handleImageMouseMove}
              onMouseLeave={handleMouseLeave}
            >
              <img
                className="img-fluid mx-auto d-block"
                src={mainImage}
                alt="Main Product"
              />
            </div>
          )}
        </div>
        {isOutsideBoxVisible && (
          <div className="col-md-2 d-none d-md-block">
            <div
              className="ZoomedImageContainer"
              style={{
                width: "500px",
                height: "500px",
                overflow: "hidden",
                position:"relative",
                zIndex:"999"
              }}
            >
              {/* This div is the fixed outside box */}
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                {/* This div contains the zoomed image */}
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden", 
                  }}
                >
                  <img
                    className="img-fluid mx-auto d-block"
                    src={mainImage}
                    alt="Zoomed Product"
                    style={{
                      transformOrigin: "0 0",
                      transform: `scale(2) translate(${-cursorPosition.x}px, ${-cursorPosition.y}px)`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { ProductImages };
