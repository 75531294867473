import React, { useState, useEffect } from 'react';

function OrderTracking() {
  const [orderStatus, setOrderStatus] = useState([
    { title: 'Confirmed Order', icon: 'pe-7s-cart', date: 'Wed, 1 lth Jan', completed: true },
    { title: 'Processing Order', icon: 'pe-7s-config', date: 'Wed, 1 lth Jan', completed: true },
    { title: 'Shipped', icon: 'pe-7s-medal', date: 'Wed, 1 lth Jan', completed: true },
    { title: 'Delivered', icon: 'pe-7s-car', date: 'Expected by, Mon 16th', completed: false },
  ]);

  // Simulate API call to update order status
  useEffect(() => {
    // Replace this with actual API call to update the order status
    // For example:
    // fetch('your_api_url')
    //   .then(response => response.json())
    //   .then(data => setOrderStatus(data));
  }, []);

  return (
    <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
      { orderStatus && orderStatus.length > 0 && orderStatus.map((step, index) => (
        <div key={index+1} className={`step ${step.completed ? 'completed' : ''}`}>
          <p className="step-title mb-2 text-secondary">{step.title}</p>
          <div className="step-icon-wrap">
            <div className="step-icon">
              <i className={step.icon}></i>
            </div>
          </div>
          <h4 className="step-title">{step.date}</h4>
        </div>
      ))}
    </div>
  );
}

export {OrderTracking};
