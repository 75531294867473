import React from "react";
import { Link } from "react-router-dom";
import { truncate } from "utils";

function MyOrders({ data }) {
  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((item) => {
          const {
            id,
            delivery,
            details,
            image,
            name,
            price,
            quantity,
            category_name,
            sub_category_name,
            product_id,
            order_id,
          } = item;
          const handleProductID = (id) => {
            sessionStorage.setItem("ordersProduct", id);
          };
          return (
            <div key={id}>
              <Link
                to={`/my-orders/${order_id}`}
                className='text-decoration-none'
                onClick={() => handleProductID(id)}
              >
                <div className='My-orders-wrapper'>
                  <div className='row mb-0 py-md-2 my-0 '>
                    <div className='col-lg-4 d-flex align-items-start'>
                      <div className='row mx-md-2'>
                        <div className='col-md-5 p-md-3 p-2 '>
                          <img
                            src={image}
                            alt={name}
                          />
                        </div>
                        <div className='col-md-7  d-flex align-items-center'>
                          <div className=''>
                            <p className='Myorders-sub-head text-secondary mb-1'>
                              {category_name !== "" ? (
                                <span>{sub_category_name}</span>
                              ) : (
                                <span>{category_name}</span>
                              )}
                            </p>
                            <p>{name}</p>
                            <Link to={`/:category/:subcategory/${product_id}`}>
                              {" "}
                              View Product {">"}{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-8 mx-md-0 mx-3 d-flex align-items-start'>
                      <div className='row w-100'>
                        <div className='col-md-4 my-orders-details'>
                          <div className=''>
                            <p className='Myorders-sub-head text-secondary mb-1'>
                              Details
                            </p>
                            <table className='border-none text-black'>
                              {details &&
                                details.length > 0 &&
                                details.slice(0, 2).map((data, index) => {
                                  const { specKey, specValue } = data;
                                  return (
                                    <tr key={index + 1}>
                                      <td>{specKey}: </td>
                                      <td>{truncate(specValue, 35)}</td>
                                    </tr>
                                  );
                                })}
                            </table>
                          </div>
                        </div>
                        <div className='col-md-2 '>
                          <div>
                            <p className='Myorders-sub-head text-secondary mb-1'>
                              Price
                            </p>
                            <p className='text-black'>${price}</p>
                          </div>
                        </div>
                        <div className='col-md-2'>
                          <div>
                            <p className='Myorders-sub-head text-secondary mb-1'>
                              Quantity
                            </p>
                            <p className='text-black'>{quantity}</p>
                          </div>
                        </div>
                        <div className='col-md-4 d-flex align-items-start'>
                          <div>
                            <p className='Myorders-sub-head text-secondary mb-1'>
                              Delivery
                            </p>
                            <table className='border-none text-black'>
                              <tr>
                                <td>Expected Delivery:</td>
                                <td>{delivery.expected_delivery}</td>
                              </tr>
                              <tr>
                                <td>Order Status:</td>
                                <td>{delivery.status}</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
    </>
  );
}

export { MyOrders };
