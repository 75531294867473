import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { truncate } from "utils/index";
import axios from "axios";
import { API_URL } from "api/apiUrls";
import {
  getSessionIdFromCookie,
  generateSessionId,
  setSessionIdInCookie,
} from "utils/index";
import redHeart from "assets/icons/red-heart.svg";
import grayHeart from "assets/icons/gray-heart.svg";

function ProductCard({ data, gstType }) {
  const [cartMsg, setCartMsg] = useState("");
  const [showCartMsg, setShowCartMsg] = useState(false);

  const token = sessionStorage.getItem("accessToken");

  useEffect(() => {
    if (!token) {
      const sessionId = getSessionIdFromCookie();

      if (!sessionId) {
        const newSessionId = generateSessionId();
        setSessionIdInCookie(newSessionId);
      } else {
        sessionStorage.setItem("sessionID", sessionId);
      }
    }
  }, [token]);

  const sessionID = sessionStorage.getItem("sessionID");

  const handleAddToCart = async (id, price) => {
    try {
      const addToCartData = {
        product: `${id}`,
        quantity: 1,
        unit_price: price,
        sub_total: 1 * price,
      };
      if (sessionID) {
        const response = await axios.post(
          `${API_URL.addToCart}?session_id=${sessionID}`,
          addToCartData
        );
        localStorage.setItem("cartID", response.data?.data.cart_id);
        setCartMsg(response.data?.message);
        setShowCartMsg(true);
      }

      if (token) {
        const response = await axios.post(API_URL.addToCart, addToCartData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        localStorage.setItem("cartID", response.data?.data.cart_id);
        setCartMsg(response.data?.message);
        setShowCartMsg(true);
      }
    } catch (error) {
      setCartMsg(error.response.data.message);
      setShowCartMsg(true);
    }
  };

  const handleClose = () => {
    window.location.reload(true);
    setShowCartMsg(false);
  };

  const navigate = useNavigate();

  const handleWishList = async (id) => {
    const token = sessionStorage.getItem("accessToken");
    if (!token) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        `${API_URL.addWishList}?product_id=${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setCartMsg(response.data?.message);
      setShowCartMsg(true);
    } catch (error) {
      setCartMsg(error.response.data?.message);
      setShowCartMsg(true);
    }
  };

  return (
    <>
      {data &&
        data.length > 0 &&
        data?.map((item) => {
          const {
            id,
            image,
            name,
            offer_percentage,
            price,
            sub_category_name,
            category_name,
            is_wishlist,
            product_id,
            pcdt_rating,
          } = item;
          const link = `${category_name}/${sub_category_name}`;
          const strikedPrice = Math.trunc(
            Number(price) - Number(price) * (Number(offer_percentage) / 100)
          );
          return (
            <div
              className="col-lg-3 col-md-4 col-6 mb-3 mb-lg-5 product-card-wrapper"
              key={id}
            >
              <div className=" text-decoration-none h-100">
                <div className="Product-Card">
                  <div className="text-decoration-none">
                    <div className="p-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          {offer_percentage !== "0" && (
                            <div
                              className="badge text-white py-md-2 px-md-3 py-2 px-2"
                              style={{ background: "#F47E11" }}
                            >
                              {offer_percentage}% Off
                            </div>
                          )}
                        </div>
                        <div
                          className="wishlist d-flex justify-content-center align-items-center"
                          onClick={() => handleWishList(product_id)}
                        >
                          {is_wishlist ? (
                            <img src={redHeart} alt="gray-heart" />
                          ) : (
                            <img src={grayHeart} alt="red-heart" />
                          )}
                        </div>
                      </div>
                      <Link
                        className="text-decoration-none"
                        to={`/${link
                          .split(" ")
                          .join("-")
                          .toLowerCase()}/${product_id}`}
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        <div className="Product-Card-image py-md-2 py-1 d-flex justify-content-center align-items-center">
                          <img src={image} className="img-fluid" alt="" />
                        </div>
                        <p className="Product-Card-subhead text-body-secondary mb-1 text-truncate">
                          {sub_category_name === ""
                            ? truncate(category_name, 10)
                            : truncate(sub_category_name, 15)}
                          {pcdt_rating && (
                            <span className="Product-Card-subhead-rating">
                              {pcdt_rating.toString().slice(0, 3)}{" "}
                              <span>&#9733;</span>
                            </span>
                          )}
                        </p>
                        <p className="Product-Card-link text-truncate">
                          {" "}
                          {truncate(name, 25)}
                        </p>
                        {gstType === "inc" ? (
                          <div className="pt-md-1">
                            <strong className="Product-Card-price  text-black me-2">
                              $ {strikedPrice + Number(strikedPrice * 0.15)}
                              <span className="Product-Card-price-gst">
                                {"(Inc GST)"}
                              </span>
                            </strong>
                          </div>
                        ) : (
                          <div className="pt-md-1">
                            <strong className="Product-Card-price  text-black me-2">
                              ${strikedPrice}
                              <span className="Product-Card-price-gst">
                                {"(Exc GST)"}
                              </span>
                            </strong>
                          </div>
                        )}

                        {offer_percentage !== "0" && (
                          <div>
                            <strike className="text-body-secondary">
                              ${price}
                            </strike>
                          </div>
                        )}
                      </Link>
                    </div>
                  </div>
                  <button
                    className="add-to-cart-btn text-center w-100 border-0 rounded-0 text-white py-2"
                    onClick={() =>
                      handleAddToCart(product_id, strikedPrice, is_wishlist)
                    }
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      {showCartMsg && (
        <div className="cart-popup text-center">
          <div className="cart-message">{cartMsg}</div>
          <button className="close-cart-popup" onClick={() => handleClose()}>
            Close
          </button>
        </div>
      )}
    </>
  );
}

export { ProductCard };
